
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <h5>Ihre Kontaktdaten</h5>
      <ProfileForm ref="profileForm" />
      <div class="action-buttons">
        <button class="button primary tall" :disabled="saveButtonDisabled || !formValidates" @click="onSaveProfile">{{ showSaveConfirmation ? 'Gespeichert' : 'Profil speichern' }}</button>
      </div>
      <h5>App Info</h5>
      <div class="app-info-container">
        <AppInfo environment="admin" @team:leaverequest="leaveTeamRequested = true"/>
      </div>
      <router-link class="logout no-underline thick noselect" to="/logout">Ausloggen</router-link>
    </div>
  </div>
  <AlertScreen :isPresented="leaveTeamRequested === true">
    <template #icon>
    </template>
    <template #message>Team verlassen</template>
    <template #content>
      <span v-if="ownsTeam">Sie können das Team nicht verlassen, da Sie der Teamleiter sind.</span>
      <span v-else>Bitte wenden Sie sich an den Teamleiter, um das Team zu verlassen.</span>
    </template>
    <template #actions>
      <button class="button primary full-width" @click.prevent="onCancel">OK</button>
    </template>
  </AlertScreen>
</template>

<script>

import { ProfileForm, AppInfo, AlertScreen } from "@profineberater/configurator-clientlib"
import TeamMixinVue from "../components/mixins/TeamMixin.vue"

export default {
  name: 'ProfilePage',
  mixins: [TeamMixinVue],
  components: {
    ProfileForm,
    AppInfo,
    AlertScreen
  },
  data: function() {
    return {
      showSaveConfirmation: false,
      saveButtonDisabled: false,
      leaveTeamRequested: {
        type: Boolean,
        default: false
      }
    }
  },
  methods: {
    onSaveProfile() {
      this.saveButtonDisabled = true
      this.$store.dispatch("user/saveProfile").then( () => {
        setTimeout( () => {
          this.showSaveConfirmation = true
          setTimeout( () => {
            this.showSaveConfirmation = false
            this.saveButtonDisabled = false
          }, 1000)
        }, 200)
      })
    },
    onCancel() {
      this.leaveTeamRequested = false
    }
  },
  computed: {
    formValidates() {
      let validationModel = this.$store.getters["ui/profileValidationModel"]
      return validationModel.validates !== false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch("user/fetchProfile").then( () => {
        vm.$refs.profileForm.validateForm()
      })
    })
  }
}
</script>

<style>
  h5 {
    text-align: left;
  }
  .logout {
    display: block;
    margin-bottom: 40px;
  }

@media only screen and (min-width : 1281px) {
  .action-buttons {
    display: none;
  }
}
@media only screen and (max-width : 1023px) {
  .main {
    width: 100vw;
  }
  .app-info-container {

    margin-bottom: 20px;
  }
  table.app-info {
    margin-bottom: 0;
  }

  :deep(::-webkit-scrollbar) {
    height: unset;
  }
  table.ios-style tr td:last-child {
    word-break: break-all;
  }
}
</style>
