import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import InlineSvg from 'vue-inline-svg';
import { PouchDbPlugin } from "@profineberater/configurator-clientlib"
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import VueGtag from "vue-gtag";

let app = createApp(App)
app.component('inline-svg', InlineSvg)
app.use(store)
app.use(PouchDbPlugin, { store: store })

app.use(VueKeyCloak, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    // onLoad: "login-required",
    // There is a keycloak property to switch of check-sso (possibly necessary for offline)
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },

  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
  },

  onReady: (keycloak) => {
    window.$keycloak = keycloak // USE window.$keycloak ONLY. NEVER use this.$keycloak (implicitly set by vue-keycloak)
    window.$logKeycloak && console.log(`I wonder what Keycloak returns:`,  window.$keycloak)
    app.config.globalProperties.$pouchDbPlugin.setupUserDB()
    app.config.globalProperties.$pouchDbPlugin.setupContentDB()
  }

})

app.use(router)

app.use(VueGtag, {
  config: {
    id: "G-08174NN2PM",
    params: {
      anonymize_ip: true
    }
  },
  enabled: false
}, router)

app.mount('#app')
