<template>
  <div class="member-projects">
    <InPageLoadingIndicator v-if="!pageLoaded" message="Lade Projekte ..." />
    <form v-else>
      <div v-for="(member, index) in memberProjectsMap" v-bind:key="'member_' + index">

        <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
          <template #title>
            <h4>{{ member.ownerName }}</h4>
            <RolesBadges class="roles-badge" :roles="member.ownerRoles" />
          </template>
          <ProjectItem :headerOnly="true" :showActionHeader="false"/>

          <WidgetContainer>
            <ProjectItem class="project-item"
              v-for="(project, index) in member.projects"
              v-bind:key="'project_' + index"
              :project="project"
              :checkoutInfo="checkouts ? checkouts[project._id] : null"
              :holderId="member.owner"
              :holderName="member.ownerName"
              source="team-projects"
              :team="team"
              @project:assign="onAssignProject"
            />

            <div v-if="member.projects.length == 0" class="table-footer no-projects">Keine Projekte.</div>
            <div v-if="lentProjectsInfo(member.owner, member.projects).length"
              :class="'table-footer' + (availableProjects(member.owner, member.projects).length > 0 ? ' with-margin-top' : '')"
            >{{ lentProjectsInfo(member.owner, member.projects) }}</div>

          </WidgetContainer>

        </WidgetRow>

      </div>
    </form>
  </div>
</template>

<script>
import UiMixin from "../mixins/UiMixin.vue"
import TeamMixin from "../mixins/TeamMixin.vue"
import InPageLoadingIndicator from "../ui/InPageLoadingIndicator.vue";
import ProjectItem from "../projects/ProjectItem.vue";
import WidgetContainer from '../widgets/WidgetContainer.vue'
import WidgetRow from "../widgets/WidgetRow.vue"
import RolesBadges from "@/components/user/RolesBadges.vue"
import axios from "axios"

const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL

export default {
  name: "MemberProjects",
  mixins: [UiMixin, TeamMixin],
  components: {
    InPageLoadingIndicator,
    ProjectItem,
    WidgetContainer,
    WidgetRow,
    RolesBadges
  },
  props: {
    checkouts: {
      type: Object,
      required: false
    },
    team: {
      type: Object,
      required: false
    }
  },
  data: function () {
    return {
      memberProjectsMap: {},
      error: null
    }
  },
  computed: {
    userId() {
      return this.$store.getters["user/userId"]
    },
  },
  methods: {
    getProjects() {
      axios({
        url: `${baseURL}/api/v1/team/projects`,
        method: 'get',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
      }).then((result) => {
        this.memberProjectsMap = result.data
        this.pageLoaded = true
      }).catch(error => {
        console.error("Failed to load projects", error)
        this.$store.commit("ui/error", "Beim Laden der Projekte ist ein Fehler aufgetreten.")
        this.pageLoaded = true
      })
    },
    onAssignProject(fromUserId, toUserId, project) {
      this.$emit("project:assign", fromUserId, toUserId, project)
    },
    refresh() {
      this.memberProjectsMap = {}
      this.pageLoaded = false
      this.getProjects()
    },
  },
  created() {
    this.getProjects()
  }
};
</script>

<style scoped>
.member-projects {}

td {
  text-align: left;
}

th {
  padding: 20px 20px 0 20px;
}

:deep(table.ios-style tr td:last-child) {
  text-align: left;
}

table {
  margin: 40px 0 40px 0;
}

.edit-project-button {
  float: right;
}

h4 {
  margin-bottom: 10px;
}

.roles-badge {
  margin-bottom: 20px;
}

.widget-container {
  padding-left: 0;
  padding-right: 0;
}

</style>
