const initialState = {
  isInfoBarShown: false,
  infoBarPath: null,
  content: [
    {
      path: "/foo/bar",
      contents: [
        {
          icon: "",
          title: "",
          text: ""
        }
      ]
    }
  ]
}

export const info = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    isInfoBarShown(state, isInfoBarShown) {
      state.isInfoBarShown = isInfoBarShown
    },
    infoBarPath(state, infoBarPath) {
      state.infoBarPath = infoBarPath
    },
  },
  getters: {
    contentByPath: (state) => (path) => {
      return state.content.find( c => c.path == path)
    },
  }
};
