
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <template #title>
          <h4 class="widget-title">Apps</h4>
          <span class="widget-subtitle">Ihre Apps im Überblick.</span>
        </template>
        <WidgetContainer class="app-container">
          <AppTile />
        </WidgetContainer>
      </WidgetRow>
    </div>
  </div>
</template>

<script>

import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import WidgetRow from "../components/widgets/WidgetRow.vue"
import AppTile from "../components/apps/AppTile.vue"

export default {
  name: 'AppsPage',
  components: {
    WidgetContainer,
    WidgetRow,
    AppTile
  },
  computed: {
  }
}
</script>
<style scoped>
.app-container {
  width: 350px;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .app-container {
    width: unset;
    padding: 0;
  }

}
</style>
