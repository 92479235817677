
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <TosLinks />
    </div>
  </div>
</template>

<script>

import { TosLinks } from "@profineberater/configurator-clientlib"

export default {
  name: 'LegalPage',
  components: {
    TosLinks,
  },
}
</script>
