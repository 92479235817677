<template>
  <div class="dashboard flex vertical full-height">
    <div class="scrollable-container main">
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1" class="welcome-mobile-only">
        <WidgetContainer class="welcome-mobile-widget">
          <WelcomeText />

          <button v-if="!isDesktop" type="button" class="button primary" @click="openApp()">App installieren</button>

          <router-link v-else to="/apps">
            <button type="button" class="button primary">App installieren</button>
          </router-link>
        </WidgetContainer>
      </WidgetRow>
      <UserNotifications @notification:navigate="onNavigate"/>
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <template #title>
          <h4 class="widget-title">Aktuelle Projekte</h4>
          <span class="widget-subtitle"></span>
        </template>
        <WidgetContainer class="full-width">
          <LatestProjects />
        </WidgetContainer>
      </WidgetRow>
      <WidgetRow flexGrow="0" v-if="true">
        <div class="lower-widget-row">
          <WidgetContainer>
            <OverviewWidget />
          </WidgetContainer>
          <WidgetContainer v-if="!isInfoBarShown" class="nopadding stats-container">
            <ProfileWidget v-if="profile" class="profile-widget" />
          </WidgetContainer>
        </div>
      </WidgetRow>
    </div>
  </div>
</template>

<script>
import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import LatestProjects from "../components/widgets/LatestProjects.vue"
import WidgetRow from "../components/widgets/WidgetRow.vue"
import ProfileWidget from "../components/widgets/ProfileWidget.vue"
import OverviewWidget from "../components/widgets/OverviewWidget.vue"
import WelcomeText from "../components/welcome/WelcomeText.vue"
import { BrowserCheckMixin, UserNotifications } from "@profineberater/configurator-clientlib"

export default {
  name: 'DashboardPage',
  mixins: [BrowserCheckMixin],
  components: {
    WidgetContainer,
    WidgetRow,
    ProfileWidget,
    LatestProjects,
    OverviewWidget,
    WelcomeText,
    UserNotifications
  },
  computed: {
    isInfoBarShown() {
      return this.$store.state.info.isInfoBarShown
    },
    isDesktop() {
      return this.currentDevice == "desktop"
    },
    appUrl() {
      return process.env.VUE_APP_CONFIGURATOR_APP_URL
    },
    profile() {
      return this.$store.getters["user/profile"]
    },
  },
  methods: {
    openApp() {
      document.location.href = this.appUrl
    },
    onNavigate(payload) {
      if (payload.reload) {
        this.$store.commit("ui/reload", true)
        this.$router.push({ path: payload.path })
        //this.$router.push({ path: '/redirect', query: { path: payload.path } })
      } else {
        this.$router.push({ path: payload.path })
      }
    }
  },
  mounted() {
    this.$store.dispatch("user/fetchProfile")
    this.checkBrowser()
  }
}
</script>

<style scoped>
.lower-widget-row {
  /* grid-template-columns: 310px 1fr; */
  display: flex;
  width: 100%;
  flex-direction: row;
}
.profile-widget {
  min-width: 310px;
  max-width: 310px;
}
.stats-container {
  flex-grow: 0;
  margin-left: 20px;
}
.latest-mediaplans-widget {
  flex-grow: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: all .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  min-width: 0;
  max-width: 0;
  padding: 0;
  margin: 0;
}

.welcome-mobile-only {
    display: none;
}
.welcome-mobile-widget {
  flex-direction: column;
}

@media only screen and (max-width : 1280px) {
  .lower-widget-row {
    flex-direction: column;
  }
  .stats-container {
    margin: 20px 0;
  }
  .profile-widget {
    max-width: unset;
  }
  .welcome-mobile-only {
    display: block;
  }
  :deep(.team-invitation) {
    flex-direction: column;
  }
  :deep(.team-invitation .actions) {
    flex-direction: column-reverse;
    margin-top: 25px;
  }
  :deep(.no-projects) {
    padding: 0 10px
  }
}
</style>
