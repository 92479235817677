
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <h2>Herzlichen Glückwunsch!</h2>
      <h3>Sie haben ein Team gegründet.</h3>
      <button class="button primary" @click="onManageTeam">Team verwalten</button>
    </div>
  </div>
</template>

<script>
import TeamMixin from '../components/mixins/TeamMixin.vue';

export default {
  name: 'CreateTeamSuccessPage',
  mixins: [TeamMixin],
  components: {
  },
  data: function() {
    return {
    }
  },
  props: {
  },
  computed: {
    team() {
      return this.$store.getters["team/team"]
    },
  },
  created() {
    this.$store.dispatch("team/fetchTeam")
  },
  methods: {
    onManageTeam() {
      this.$router.push({ path: '/team', query: { reload: 'true' } })
    }
  }
}
</script>

<style scoped>

</style>
