<template>
  <div class="loading-spinner lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style scoped>
.loading-spinner {
    position: absolute;
    top: calc(50% - 86px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
</style>
