<template>
  <div class="site-menu">
    <MainNav mode="mobile" class="overlay-nav" @navigate:topath="onNavigate"/>
  </div>
</template>

<script>

import MainNav from '../nav/MainNav.vue';

export default {
  name: 'SiteMenu',
  components: {
    MainNav
  },
  methods: {
    onNavigate() {
      this.$store.commit("ui/isSiteMenuShown", false)
    }
  }
}
</script>
