const initialState = {
  isDevModeEnabled: false,
}

export const admin = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    isDevModeEnabled(state, isDevModeEnabled) {
      state.isDevModeEnabled = isDevModeEnabled
    },
  },
};
