
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <form>
        <h4 class="widget-title">Zahlungsdaten</h4>
        <span class="widget-subtitle">Verwalten Sie hier Ihre Zahlungsdaten.</span>
        <table class="ios-style">
          <tr>
            <th class="noselect">Zahlungsart</th>
            <th class="noselect">VISA</th>
            <th colspan="3" class="noselect">Aktionen</th>
          </tr>
          <tr>
            <th class="noselect">Kartennummer</th>
            <th class="noselect">4111 1234 5678 9121</th>
            <th colspan="3" class="noselect">Aktionen</th>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
// import { DropDown } from "@profineberater/configurator-clientlib"

export default {
  name: 'PaymentPage',
  components: {
    //DropDown
  },
  props: {
  },
  computed: {
  }
}
</script>

<style scoped>
td {
  text-align: left;
}
th {
  padding: 20px 20px 0 20px;
}
:deep(table.ios-style tr td:last-child) {
  text-align: left;
}

table {
  margin: 40px 0 40px 0;
}
</style>
