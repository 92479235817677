<template>
  <div class="assortment-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Ihr Sortiment
            </h1>
            <div class="subtitle">Sie können hier das Sortiment anpassen, dass in der Framework App zur Auswahl steht. Wählen Sie die Checkboxen der jeweiligen Produkte an oder ab.
              <p>Das Sortiment wird automatisch mit der App synchronisiert, sobald diese geöffnet und mit dem Internet verbunden ist. Nach der Synchronisation ist Ihr Sortiment auch offline verfügbar.</p>
              <p v-if="teamEnabled">Hinweis: Das Sortiment gilt für das gesamte Team.</p>
            </div>
          </div>
        </template>
        <template #actions>
          <AssortmentSaveButtons />
        </template>
    </SidebarContent>
  </div>
</template>
<script>
import AssortmentSaveButtons from "./AssortmentSaveButtons.vue";
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'AssortmentSidebar',
  components: {
    SidebarContent,
    AssortmentSaveButtons
},
  data: function() {
    return {
      didSave: false
    }
  },
  computed: {
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
  },
}
</script>

<style scoped>
.assortment-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
