<template>
  <div class="editable-team-members">
    <TeamMembersListView :headerOnly="true" />
    <TeamMembersListView />
  </div>
</template>

<script>
import TeamMembersListView from "./TeamMembersListView.vue";

export default {
  name: "TeamMembers",
  components: {
    TeamMembersListView,
  },
  computed: {
    team() {
      return this.$store.getters["team/team"]
    },
  },
  methods: {
    getTeam() {
      this.$store.dispatch("team/fetchTeam").catch( error => {
        console.error("Failed to load team", error)
        this.$store.commit("ui/error", "Beim Laden der Teamdaten ist ein Fehler aufgetreten.")
      })
    },
  },
  created() {
    this.getTeam()
  }
};
</script>

