const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
function parseLocaleNumber(stringNumber, locale) {
    var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
}

export default class Util {
    static contextMenuItems(collection, mappingFunction) {
        let menuItemsMap = {}
        collection.forEach(item => {
            menuItemsMap[item.id] = mappingFunction(item)
        })
        return menuItemsMap
    }

    static formatNumber(number, opts = {
        maximumFractionDigits: 0
    }) {
        return number ? number.toLocaleString("de-DE", opts) : 0
    }

    static formatCurrency(number) {
        if (Number.isNaN(number)) {
            return currencyFormatter.format(0)
        }
        return currencyFormatter.format(number)
    }

    static parseCurrency(str) {
        return parseLocaleNumber(str, "de-DE")
    }

    static parseNumber(str) {
        return parseLocaleNumber(str, "de-DE")
    }

    static sortByString(array, propertyName, ascending) {
        return array.sort(function(x, y) {
            let a = ascending ? x : y
            let b = ascending ? y : x
            if (a[propertyName] == b[propertyName]) return 0
            return a[propertyName] > b[propertyName] ? 1 : -1
        })
    }
    static sortByNumber(array, propertyName, ascending) {
        return array.sort(function(x, y) {
            let a = ascending ? x : y
            let b = ascending ? y : x
            return a[propertyName] - b[propertyName]
          })
    }
    static sortByTimestamp(array, propertyName, ascending) {
        return array.sort(function(x, y) {
            let a = ascending ? x : y
            let b = ascending ? y : x
            return (new Date(a[propertyName])?.getTime() || 0) - (new Date(b[propertyName])?.getTime() || 0);
        })
    }
}