
<template>
  <div class="user-roles">
    <div :class="'badge ' + role" v-for="(role, index) in userRoles" v-bind:key="'user_' + index">{{ roleLabel(role) }}</div>
    <div :class="'badge ' + role" v-for="(role, index) in keycloakRoles" v-bind:key="'kc_' + index">{{ role }}</div>
  </div>
</template>

<script>

import { Roles } from "@profineberater/configurator-shared/dist/configurator-shared-lib"

export default {
  name: 'RolesBadges',
  props: {
    roles: {
      type: Array,
      default: null
    },
    showAllRoles: {
      type: Boolean
    }
  },
  computed: {
    allRoles() {
      return this.roles || window.$keycloak.realmAccess.roles
    },
    userRoles() {
      return this.allRoles.filter( r => r.startsWith("user-") )
    },
    keycloakRoles() {
      return this.showAllRoles ? this.allRoles.filter( r => !r.startsWith("user-") ) : []
    }
  },
  methods: {
    roleLabel( r ) {
      return Roles.getUserFriendlyRoleName(r)
    }
  }
}
</script>

<style scoped>
.user-roles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.badge {
  background-color: grey;
  color: white;
  font-weight: 900;
  font-size: 8px;
  padding: 5px 8px;
  margin-right: 10px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  height: 22px;
  line-height: initial;
}

@-moz-document url-prefix() {
  .badge {
    padding: 7px 8px;
  }
}

.badge.admin-role-superadmin {
  background-color: orangered;
}
.badge.admin-role-admin {
  background-color: red;
}
.badge.user-role-teamleader {

  border: var(--color-role-teamleader) 1px solid;
  background-color: rgba(224, 95, 255, 0.07);
  color: var(--color-role-teamleader);

}
.badge.user-role-manager {
  border: var(--color-role-manager) 1px solid;
  background-color: rgba(120, 95, 255, 0.07);
  color: var(--color-role-manager);
}
.badge.user-role-staff {
  border: var(--color-role-staff) 1px solid;
  background-color: rgba(47, 149, 255, 0.07);
  color: var(--color-role-staff);
}
</style>
