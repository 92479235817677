
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <div class="info">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GlobalAdminPage',
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
iframe {
  width: 90%;
  height: 80%;
  border: 1px solid #666;
}
button {
  margin-right: 20px;
}
button:last-child {
  margin-right: 0;
}
.info {
  margin: 40px;
}
</style>