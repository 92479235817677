
<template>
  <div class="loading">
    <div class="lds-dual-ring spinner"></div>
    {{ message }}
  </div>
</template>

<script>

export default {
  name: 'InPageLoadingIndicator',
  props: {
    message: {
      type: String
    }
  },
}
</script>

<style scoped>
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spinner {
  margin-bottom: 20px;
}
</style>
