<template>
  <div class="profile-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Zahlungsdaten
            </h1>
            <div class="subtitle">Hier verwalten Sie Ihre Zahlungsdaten.</div>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
            <button disabled="true" class="button primary tall">Speichern</button>
            <!-- <button class="button ghost tall" @click="onPlan">Second important Task</button> -->
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'PaymentSidebar',
  components: {
      SidebarContent,
  },
  props: {
  },
  data: function() {
    return {
      showSaveConfirmation: false,
      saveButtonDisabled: false
    }
  },
  methods: {
    onSaveProfile() {
      this.saveButtonDisabled = true
      this.$store.dispatch("user/saveProfile").then( () => {
        setTimeout( () => {
          this.showSaveConfirmation = true
          setTimeout( () => {
            this.showSaveConfirmation = false
            this.saveButtonDisabled = false
          }, 1000)
        }, 200)
      })
    }
  },
  computed: {
    profile() {
      return this.$store.getters["user/profile"]
    },
    user() {
      return this.profile.user
    },
    rev() {
      return this.profile._rev
    }
  },
}
</script>

<style scoped>
.profile-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
