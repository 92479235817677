<template>
  <div class="apps-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1>
                Ihre Apps
            </h1>
            <div class="subtitle">
              <h5>Eine App für alle Plattformen</h5>
              <p>Sie können die Apps auf Android- oder iOS-Geräten installieren. Nutzen Sie dazu den angezeigten QR-Code.</p>
              <p>Wahlweise können Sie die Apps auch im Desktop-Browser benutzen, falls Sie zum Beispiel mit einem Laptop arbeiten.</p>
            </div>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'AppsSidebar',
  components: {
      SidebarContent,
  },
  data: function() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>

.apps-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
