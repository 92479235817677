<template>
  <div></div>
</template>
<script>

import Util from "@/util.js"
import moment from "moment"
import { useUI } from '@profineberater/configurator-clientlib'

export default {
  data: function() {
    return {
      pageLoaded: false
    }
  },
  methods: {
    forceReloadAndRedirect(path, query = {}) {
      this.$store.commit('ui/reload', true);
      query.path = path
      this.$router.push({ path: '/redirect', query: query })
    },
    formatDate(date, short = false) {
      return moment(date).locale("de").format(short ? "DD.MM.YY" : "L");
    },
    formatDateTime(date) {
      return moment(date).locale("de").format('LLL');
    },
    dateInfos(item) {
      return {
        created: item.createdAt ? this.formatDate(item.createdAt) : null,
        lastModified: item.modifiedAt ? this.formatDate(item.modifiedAt) : null,
      }
    },
    formatNumber(number) {
      return Util.formatNumber(number)
    },
    download(dispatchPath, id) {
      useUI(this.$store).blockUI({ loading: true });
      this.$store
        .dispatch(dispatchPath, id)
        .then((response) => {
          let json = response.data.data
          let filename = json.filename
          const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          const link = document.createElement("a");
          link.href = `data:${mimetype};base64,${json.xlsxBase64}`;

          link.setAttribute(
            "download",
            filename
          );
          document.body.appendChild(link);
          link.click();
          useUI(this.$store).unblockUI();
        })
        .catch((err) => {
          useUI(this.$store).unblockUI();
          console.error(err);
        });
    },
  },
};
</script>
