<template>
  <div :class="clazz">
    <div class="accordion-title" @click="toggleCollapsed">
      <slot name="title">
        <span class="title">
          {{ category ? category.title : '' }}</span>
      </slot>
      <div class="accordion-action">
        <svg v-if="!disabled" :class="!collapsed ? 'plus-rotated' : ''" width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
          <path d="m1 8.5 5-5 5 5"
            style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />
        </svg>
      </div>
    </div>
    <div class="slot" :class="collapsed ? 'collapsed' : 'expanded'" ref="slot">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccordionTile',
  data: function () {
    return {
      collapsed: true
    }
  },
  props: {
    disabled: {
      type: Boolean
    }
  },
  methods: {
    toggleCollapsed() {
      if (this.disabled) return
      this.collapsed = !this.collapsed
      if (!this.collapsed) {
        this.expand()
        this.$emit("didexpand", this)
      } else {
        this.collapse()
        this.$emit("didcollapse", this)
      }
    },
    collapse() {
      this.$refs.slot.style.height = '0px'
    },
    expand() {
      let sectionHeight = this.$refs.slot.scrollHeight
      this.$refs.slot.style.height = sectionHeight + 'px'
    },
  },
  computed: {
    clazz() {
      let s = "accordion" + (this.collapsed ? " accordion-collapsed" : "")
      if (this.disabled) {
        s += " disabled"
      }
      return s
    },
  },
  mounted() {
    this.collapse()
  },
}
</script>

<style scoped>
img.icon {
  flex-grow: 0;
  padding-right: 10px;
}

span.title {
  flex-grow: 1;
}

.accordion {
  border-bottom: 1px solid #dadada;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  text-align: left;
  cursor: pointer;
  margin-bottom: -20px;
}

.accordion.disabled .accordion-title {
  cursor: default;
}

.accordion.disabled .accordion-action {
  opacity: 0.25;
}

.accordion-action {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-action svg {
  transition: all 0.25s ease-in-out;
}

.accordion {
  height: auto;
}

.accordion-collapsed {
  overflow: hidden;
}

.slot {
  padding: 0 0 0 30px;
  transition: all 0.25s ease-in-out;
}

:deep(.slot ul) {
  transition: all 0.3s ease-in-out; /* 0.3s instead of 0.25s for smoother appearance */
}

:deep(.slot.expanded ul) {
  opacity: 1;
}

:deep(.slot.collapsed ul) {
  opacity: 0;
}

.plus {
  color: #000;
}

.plus-rotated {
  transform: rotate(180deg);
}
</style>
