<template>
  <div class="sent-invitations">
    <form>
        <table class="ios-style">
          <tr>
            <th colspan="4">
              <slot name="header"></slot>
            </th>
          </tr>
          <tr v-for="(invitation, index) in sentInvitations" v-bind:key="index">
            <td class="team select">{{ invitation.recipient.email }}</td>
            <td class="team noselect">{{ formatDateTime(invitation.sentAt) }}</td>
            <td class="team noselect">{{ invitationStatusLabel(invitation) }}</td>
            <td class="team noselect action">
              <!-- <a v-if="invitation.status == 'rejected'" @click="onResendInvitation(invitation)">Erneut einladen</a>&nbsp; -->
              <a v-if="invitation.status == 'sent'" @click="onRevokeInvitation(invitation)">Widerrufen</a>&nbsp;
              <a v-if="invitation.status != 'sent'" @click="onRevokeInvitation(invitation)">Löschen</a>
            </td>
          </tr>
          <tr v-if="sentInvitations.length == 0">
            <td colspan="4">Keine offenen Einladungen</td>
          </tr>
        </table>
      </form>
  </div>
</template>

<script>
import axios from "axios"
import UiMixin from "../mixins/UiMixin.vue"

const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL
export const INVITATION_STATUS_LABELS = Object.freeze({
  sent: "Gesendet",
  accepted: "Angenommen",
  rejected: "Abgelehnt",
  expired: "Abgelaufen",
  resigned: "Team verlassen"
})

export default {
  name: "SentInvitations",
  mixins: [UiMixin],
  data: function() {
    return {
      sentInvitations: [],
      error: null
    }
  },
  methods: {
    getSentInvitations() {
      axios({
        url: `${baseURL}/api/v1/invitations/sent`,
        method: 'get',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
      }).then((result) => {
        this.sentInvitations = result.data
      }).catch( error => {
        this.error = error
      })
    },
    onRevokeInvitation(invitation) {
      axios({
        url: `${baseURL}/api/v1/invitations/${invitation.id}`,
        method: 'delete',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` }
      }).then(() => {
        this.getSentInvitations()
      }).catch( error => {
        this.error = error
      })
    },
    onResendInvitation(invitation) {
      let errorOccured = false
      axios({
        url: `${baseURL}/api/v1/invitations`,
        method: 'post',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
        data: {
          recipient: invitation.recipient.email,
        }
      }).then(() => {
        axios({
        url: `${baseURL}/api/v1/invitations/${invitation.id}`,
        method: 'delete',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
        }).then(() => {
          this.refresh()
        }).catch( () => {
          errorOccured = true
        })
      }).catch( () => {
        errorOccured = true
      }).finally( () => {
        this.refresh()
      })
      if (errorOccured) {
        this.$store.commit("ui/error", "Beim Versenden der Einladung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Falls das Problem weiterhin besteht wenden Sie sich bitte an den Support.")
      }
    },
    refresh() {
      this.getSentInvitations()
    },
    invitationStatusLabel(invitation) {
      return INVITATION_STATUS_LABELS[invitation.status] || "Unbekannt"
    }
  },
  created() {
    this.refresh()
  }
};
</script>

<style scoped>
.sent-invitations {

}

td {
  text-align: left;
}
td.team.action {
  text-align: right;
}
th {
  padding: 20px 20px 0 20px;
}
:deep(table.ios-style tr td.team:last-child) {
  text-align: right;
  padding-right: 20px;
}

@media only screen and (max-width : 1023px) {
  .sent-invitations {
    overflow-x: auto;
    width: 100vw;
  }
  td.team {
    white-space: nowrap;
  }

}
@media only screen and (min-width : 1024px) {

}
</style>
