<template>
  <div class="widget-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WidgetContainer',
}
</script>

<style scoped>
.widget-container {
  background: #fff;
  border-radius: 12px;
  box-shadow: var(--default-box-shadow);
  flex-grow: 1;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
}
.widget-container.nopadding {
  padding: 0;
}
.widget-container.full-width {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width : 1280px) {
  .widget-container {
    /*
    padding: 40px 20px;
    */
    justify-content: center;
  }
}

</style>

<style>
.widget-container > div {
  flex-grow: 1;
}
.widget-background-blue {
    background-color: var(--c-button-blue);
}


</style>