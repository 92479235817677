<template>
  <div class="configuration-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
            </h1>
            <div class="subtitle">
            </div>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
            <!-- <button class="button primary warn tall" @click="onPublish"></button> -->
            <!-- <button class="button ghost tall" @click="onPlan">Second important Task</button> -->
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'GlobalAdminSidebar',
  components: {
      SidebarContent,
  },
  props: {
  },
  methods: {
    onPublish() {
      console.error("TODO onPublish")
    }
  }
}
</script>

<style scoped>
.configuration-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
