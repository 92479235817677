import {
  createStore
} from 'vuex'

import { ui } from './ui.module';
import { info } from './info.module';
import { admin } from './admin.module';

import {
  configurationOptionsStoreModule as configurationOptions,
  projectStoreModule as project,
  dbStoreModule as db,
  userStoreModule as user,
  contentModule as content,
  teamModule as team,
} from '@profineberater/configurator-clientlib';

//import VuexPersistence from 'vuex-persist'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// })

const store = createStore({
  //plugins: [vuexLocal.plugin],
  modules: {
    ui,
    info,
    admin,
    project,
    db,
    configurationOptions,
    user,
    content,
    team,
  }
});

export default store