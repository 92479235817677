
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main" :key="$route.fullPath">
      <InPageLoadingIndicator v-if="!pageLoaded" :message="loadingMessage"/>
      <div v-if="pageLoaded && !hasPendingInvitations">
        <h4 class="widget-title">Team</h4>

        <span class="widget-subtitle">
          <span v-if="noTeam">Sie sind kein Mitglied eines Teams.</span>
          <p v-if="!profileComplete">Bevor Sie ein Team gründen, vervollständigen Sie bitte Ihr Profil.</p>
          <span v-if="ownsTeam">Sie sind der Gründer des Teams</span>
          <span v-if="isMemberOfAnotherTeam">Sie sind Mitglied im Team von {{ teamOwnerName }}</span>
        </span>
        <div class="create-team" v-if="noTeam">
          <button v-if="profileComplete" class="button primary" @click="$router.push('/team/create')">Team
            gründen</button>
          <button v-else class="button primary" @click="$router.push('/profile')">Zum Profil</button>
        </div>

        <RolesBadges v-if="teamEnabled" />
        <form>
          <TeamForm class="team-name-form" v-if="team && canManageCompany" :editingAllowed="canManageCompany" v-model="team" @validation:result="onValidationResult"/>

          <table class="ios-style">
            <tr v-if="canManageCompany" @click="$router.push('/company')">
              <td class="noselect cursor-pointer"><a href="#">Firmendaten verwalten ...</a></td>
              <td class="noselect cursor-pointer right">
                <ArrowRight />
              </td>
            </tr>
            <tr v-if="canManagePayment" @click="$router.push('/payment')">
              <td class="noselect cursor-pointer"><a href="#">Zahlungsdaten verwalten ...</a></td>
              <td class="noselect cursor-pointer right">
                <ArrowRight />
              </td>
            </tr>
          </table>
        </form>

        <form v-if="canManageUsers">
          <h4 class="widget-title">Mitglieder</h4>
          <span class="widget-subtitle">Verwalten Sie die Mitglieder Ihres Teams.</span>
          <EditableTeamMembers @member:removed="$refs.sentInvitations.refresh()"/>
        </form>
        <form v-else-if="!canManageUsers && isMemberOfAnotherTeam">
          <h4 class="widget-title">Mitglieder</h4>
          <span class="widget-subtitle">Die Mitglieder des Teams von {{ teamOwnerName }}.</span>
          <TeamMembers />
        </form>

        <div class="invitations" v-if="canInviteUsers">
          <h4 class="widget-title">Nutzer einladen</h4>
          <span class="widget-subtitle sent-invitations">Laden Sie Nutzer in Ihr Team ein.</span>

          <SentInvitations ref="sentInvitations">
            <template #header>
              <InviteUsers @invitation:sent="onSentInvitation" />
            </template>
          </SentInvitations>
        </div>
      </div>
      <section v-if="pageLoaded && hasPendingInvitations">
        <div>
          <h2>Einladung zum Team</h2>
          <p>Sie wurden zu einem Team eingeladen. Bitte prüfen Sie Ihre Benachrichtigungen auf dem Dashboard.</p>
        </div>
      </section>

      <section v-if="isTeamLeader">
        <div class="remove-team">
          <router-link to="/team/remove" class="button primary">Team löschen</router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { /*CompanyInfo,*/ ArrowRight, TeamForm } from "@profineberater/configurator-clientlib"
import SentInvitations from "../components/team/SentInvitations.vue";
import EditableTeamMembers from "../components/team/EditableTeamMembers.vue";
import TeamMembers from "../components/team/TeamMembers.vue";
import TeamMixin from "../components/mixins/TeamMixin.vue";
import InviteUsers from '../components/team/InviteUsers.vue';
import RolesBadges from "@/components/user/RolesBadges.vue"
import InPageLoadingIndicator from "../components/ui/InPageLoadingIndicator.vue";
import UiMixin from "../components/mixins/UiMixin.vue";

export default {
  name: 'TeamPage',
  mixins: [TeamMixin, UiMixin],
  components: {
    ArrowRight,
    SentInvitations,
    EditableTeamMembers,
    TeamMembers,
    InviteUsers,
    RolesBadges,
    InPageLoadingIndicator,
    TeamForm,
  },
  data: function() {
    return {
      loadingMessage: "Lade Team-Daten ..."
    }
  },
  computed: {
    teamCompany() {
      return this.team?.company
    },
    profile() {
      return this.$store.getters["user/profile"]
    },
    profileComplete() {
      return true //this.profile?.user?.firstName && this.profile?.user?.lastName
    },
    teamOwnerName() {
      return `${this.team?.owner?.firstName || ""} ${this.team?.owner?.lastName || ""}`
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
  },
  methods: {
    onSentInvitation() {
      this.$refs.sentInvitations.refresh()
    },
    refresh() {
      this.loadingMessage = "Lade Profil ..."
      this.$store.dispatch("user/fetchProfile").then( () => {
        this.loadingMessage = "Lade Team ..."
        this.$store.dispatch("team/fetchTeam").then( () => {
          setTimeout( () => {
            this.pageLoaded = true
          }, 250)
        }).catch( () => {
          this.pageLoaded = true
        })
      })
    },
  },
  created() {
    if (this.$route.query.delay == "true") {
      this.loadingMessage = "Aktualisiere Team-Daten ..."
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.refresh()
    })
  }
}
</script>

<style scoped>
td {
  text-align: left;
}

th {
  padding: 20px 20px 0 20px;
}

:deep(table.ios-style tr td:last-child) {
  text-align: left;
}
:deep(table.ios-style tr td.right) {
  text-align: right;
}
:deep(table.ios-style tr th) {
  padding-bottom: 20px;
}
:deep(table.ios-style tr td.team.noselect.roles) {
	padding-bottom: 10px;
	padding-top: 10px;
}
table {
  margin: 40px 0 40px 0;
}

td.right {
  text-align: right;
}

.main {
  padding-bottom: 40px;
  text-align: left;
}
.remove-team {
  margin-top: 40px;
}
.team-name-form {
  margin-top: 20px;
}

@media only screen and (max-width : 1023px) {

  .scrollable-container.main div {
    width: 95vw;
  }
  :deep(.team-members-list-view table.ios-style),
  :deep(.team-name-form table.ios-style) {
    width: 95vw;
  }
  :deep(.team-members-list-view table.ios-style td.team),
  :deep(.team-form table.ios-style td.cursor-pointer) {
    white-space: nowrap;
  }
}
@media only screen and (max-width : 767px) {
  .scrollable-container.main div {
    width: 90vw;
  }
   :deep(.team-members-list-view table.ios-style),
   :deep(.team-name-form table.ios-style) {

    width: 90vw;
  }
  :deep(.team-form table.ios-style td.cursor-pointer) {
    white-space: nowrap;
  }

}
</style>
