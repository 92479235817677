<template>
  <div class="invite-users">
    <TextInput
      class="invitee-input"
      placeholder="Hier bitte E-Mail-Adresse eingeben"
      v-model="invitee.email"
      :errorMessage="invitee.error"
      :showsErrorOutline="invitee.error"
      :showErrors="invitee.error"
    />
    <button class="button mediumsize primary invite-button" :disabled="isUserEmail(invitee.email) || !isValidEmail(invitee.email) || isRequestPending" @click.prevent="onInviteUser(invitee)">
      <span v-if="!isRequestPending">{{ inviteButtonLabel(invitee) }}</span>
      <AnimatedSyncIcon v-else/>
    </button>
  </div>
</template>

<script>
import { Invitations } from "@profineberater/configurator-shared"
import { TextInput, AnimatedSyncIcon } from '@profineberater/configurator-clientlib'
import validator from 'validator';
import axios from 'axios';
const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL

export default {
  name: "InviteUsers",
  components: {
    TextInput,
    AnimatedSyncIcon
  },
  data: function() {
    return {
      error: null,
      invitee: {
        email: null,
      },
      isRequestPending: false,
    }
  },
  computed: {
    profile() {
      return this.$store.getters["user/profile"]?.user
    },
  },
  methods: {
    friendlyErrorMessage(code) {
      switch (code) {
        case Invitations.ERROR.PENDING_INVITATION_EXISTS: return "Es existiert bereits eine Einladung für diese E-Mail-Adresse."
        case Invitations.ERROR.TEAM_NOT_FOUND: return "Das Team wurde nicht gefunden."
        case Invitations.ERROR.USER_ALREADY_MEMBER: return "Der Nutzer ist bereits Mitglied eines Teams."
        case Invitations.ERROR.INTERNAL_ERROR: return "Es ist ein interner Fehler aufgetreten."
        case Invitations.ERROR.INTERNAL_EMAIL_ERROR: return "Es ist ein interner Fehler aufgetreten."
      }
      return "Es ist ein interner Fehler aufgetreten."
    },
    onInviteUser(invitee) {
      this.isRequestPending = true
      axios({
        url: `${baseURL}/api/v1/invitations`,
        method: 'post',
        headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
        data: {
          recipient: invitee.email,
        }
      }).then(() => {
        invitee.invitationSent = true
        this.$emit("invitation:sent", invitee.email)
        this.invitee.email = null
        this.isRequestPending = false
      }).catch( error => {
        this.isRequestPending = false
        this.$store.commit("ui/error", `Die Einladung konnte nicht verschickt werden. ${this.friendlyErrorMessage(error.response.data.message)}`)
      })
    },
    isValidEmail(email) {
      return validator.isEmail(email || "")
    },
    isUserEmail(email) {
      return email == this.$store.getters["user/profileEmail"] || email == this.$store.getters["user/loginEmail"]
    },
    inviteButtonLabel() {
      return "Einladen"
    }
  },
  created() {
    this.$store.dispatch("user/fetchProfile")
  }
};
</script>

<style scoped>
.invite-users {
  display: flex;
  flex-direction: row;
}
.invitee-input {
  flex-grow: 1;
  margin-right: 20px;
}
:deep(.invitee-input input) {
  height: 46px;
}
.invite-button {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.invite-button .animated-sync-icon) {
  margin-top: 4px;
  color: #fff;
}
</style>
