<template>
  <div class="editable-team-members">

    <TeamMembersListView :editable="true" :headerOnly="true" />

    <TeamMembersListView
      :editable="true"
      @role:change="onRoleChange"
      @remove:member="onRemoveMember"
    />

    <AlertScreen :isPresented="memberIdToBeRemoved != null">
      <template #icon>
      </template>
      <template #message>Wollen sie das Mitglied wirklich löschen?</template>
      <template #content> Das Mitglied wird aus dem Team entfernt. Die Projekte des Mitglieds bleiben im Konto des Mitglieds erhalten. Sie haben danach keinen Zugriff mehr auf die Projekte des Mitglieds.</template>
      <template #actions>
        <button :disabled="removeMemberDisabled" class="button primary full-width danger" @click.prevent="doRemoveMember">Mitglied entfernen</button>
        <button class="button plain full-width" @click.prevent="onCancel">Abbrechen</button>
      </template>
    </AlertScreen>
  </div>
</template>

<script>
//import axios from "axios"
import TeamMembersListView from "./TeamMembersListView.vue";
import { AlertScreen } from "@profineberater/configurator-clientlib"

//const baseURL = process.env.VUE_APP_SERVER_CONFIGURATOR_URL

export default {
  name: "EditableTeamMembers",
  components: {
    TeamMembersListView,
    AlertScreen
  },
  data: function() {
    return {
      memberIdToBeRemoved: null,
      removeMemberDisabled: false,
    }
  },
  methods: {
    onRemoveMember(memberId) {
      this.memberIdToBeRemoved = memberId
    },
    onCancel() {
      this.memberIdToBeRemoved = null
    },
    // getTeam() {
    //   axios({
    //     url: `${baseURL}/api/v1/team`,
    //     method: 'get',
    //     headers: { 'Authorization': `Bearer ${window.$keycloak.token}` },
    //   }).then((result) => {
    //     this.$store.commit("team/team", result.data)
    //   }).catch( error => {
    //     console.error("Failed to load team", error)
    //     this.$store.commit("ui/error", "Beim Laden der Teamdaten ist ein Fehler aufgetreten.")
    //   })
    // },
    onRoleChange(role, member, previousRoles) {
      if (role != previousRoles[0]) {
        this.$store.dispatch("team/assignRole", {
          assignee: member.id,
          role: role
        }).then( () => {
          this.$nextTick( () => {
            this.refresh()
          })
        }).catch( err => {
          console.log("Failed to assign role", err)
          this.$store.commit("ui/error", "Bei der Zuweisung der Rolle ist ein Fehler aufgetreten.")
        })
      }
    },
    doRemoveMember() {
      this.removeMemberDisabled = true
      this.$store.dispatch("team/removeMember", this.memberIdToBeRemoved).then( () => {
        this.$nextTick( () => {
          this.refresh()
          this.memberIdToBeRemoved = null
          this.removeMemberDisabled = false
          this.$emit("member:removed")
        })
      }).catch( err => {
        console.log("Failed to remove member", err)
        this.memberIdToBeRemoved = null
        this.removeMemberDisabled = false
        this.$store.commit("ui/error", "Beim Entfernen des Mitglieds ist ein Fehler aufgetreten.")
      })
    },
    refresh() {
      this.$store.dispatch("team/fetchTeam")
    }
  },
  created() {
    this.refresh()
  }
}
</script>
<style scoped>
</style>