<template>
  <div class="menu-overlay-page">
    <FullFramePageContainer>
      <div class="container">
        <div class="col center">
          <SiteMenu />
        </div>
      </div>
      <div class="icon-button close" @click="onClose">
        <inline-svg :src="require('@/assets/svg/icons/main_window/icon_main_menu_close_24x24px.svg')" width="24" height="24"></inline-svg>
      </div>
    </FullFramePageContainer>
  </div>
</template>

<script>

import FullFramePageContainer from '@/views/FullFramePageContainer.vue'
import SiteMenu from "@/components/site/SiteMenu.vue"

export default {
  name: 'LoginPage',
  components: {
    FullFramePageContainer,
    SiteMenu,
  },
  computed: {
    showAdminPanel() {
      return process.env.VUE_APP_SHOW_DEV_OPTIONS
    }
  },
  methods: {
    onContact() {
      window.open(`mailto:${process.env.VUE_APP_SUPPORT_EMAIL}`)
    },
    onClose() {
      this.$store.commit("ui/isSiteMenuShown", false)
    }
  }
}
</script>

<style scoped>
.menu-overlay-page {
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}
.container > div {
  flex-grow: 1;
  flex-basis: 0;
}
.col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact {
  color: var(--c-deep-grey);
  line-height: 1.5;
}
.contact button {
  margin-top: 20px;
  width: 248px;
}
.company-name {
  color: var(--c-dark-blue);
}
.icon-button.close {
  position: fixed;
  top: 40px;
  right: 40px;
}
</style>
