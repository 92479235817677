<template>
  <div class="report-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <div class="app-icon"><img src="@/assets/svg/icons/reports/framework_app_icon.svg" width="89" height="89"></div>
            <h1>Framework<br>App Analytics</h1>
            <div class="subtitle">
              <p>Hier befinden Sie sich im Analytics Bereich der Framework App.</p>
            </div>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
            <!-- <router-link to="/apps">
              <button type="button" class="button primary">App installieren</button>
            </router-link> -->
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'ReportSidebar',
  components: {
    SidebarContent,
  },
}
</script>

<style scoped>
.report-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}

.app-icon img {
  width: 128px;
}
</style>
