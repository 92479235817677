<template>
  <div class="app-tile">

    <div class="app-icon"><img src="@/assets/img/framework-app-icon-wordmark-dropshadow.png"></div>
    <!-- <h5 class="app-title">Framework</h5> -->
    <div class="qrcode"></div>
    <p class="info">Scannen Sie diesen QR-Code auf Ihrem Android- oder iOS-Gerät, um die App zu installieren.</p>
    <p class="info">Unterstützte Browser <a href="https://profine-esolutions.com/index.html#faq" target="_blank">(mehr Info)</a>:
      <ul>
        <li>iOS: <b>Safari</b></li>
        <li>Android: <b>Chrome</b></li>
      </ul>
    </p>
    <QrCode />
    <div class="copy-app-link">
      <span v-if="showCopyCheckmark" class="copy-check">Kopiert &#10003;</span>
      <a v-else @click="copyAppLink">Link zur App in die<br>Zwischenablage kopieren</a>
    </div>

    <h6 class="open-app-desktop-title">Sie möchten die Desktop-Version nutzen?</h6>

    <button @click="openAppWindow" type="button" class="open-app-desktop button rounded ghost">
      Desktop-Version öffnen
    </button>

  </div>
</template>

<script>
import QrCode from "../qr/QrCode.vue";
import copy from 'copy-to-clipboard';

export default {
  name: 'AppTile',
  components: {
      QrCode
  },
  data: function() {
    return {
      showCopyCheckmark: false
    }
  },
  methods: {
      openAppWindow() {
        window.open(`${process.env.VUE_APP_CONFIGURATOR_APP_URL}/projects`, "Berater", "width=1280, height=800");
      },
      copyAppLink() {
        copy(process.env.VUE_APP_CONFIGURATOR_APP_URL)
        this.showCopyCheckmark = true
        setTimeout( () => {
          this.showCopyCheckmark = false
        }, 2000)
      }
  }
}
</script>

<style scoped>
.app-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 40px;
    width: 350px;
    flex-grow: 0;
    border-radius: 12px;
}

.app-title {
  margin: 20px 0 0 0;
}
.qr-title {
  margin: 20px 0 10px 0;
}
.copy-check {
  transition: all 0.5 ease-in-out;
  color: var(--primary-color);
  font-weight: 500;
}
.copy-app-link {
  height: 40px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
.app-icon img {
  width: 144px;
}
.app-icon {
  margin: 0 0 40px 0;
}
.open-app-desktop-title {
  margin-top: 30px;
  margin-bottom: 20px;
  color: var(--text-color);
}
h5.qr-title {
  margin-bottom: 0;
}
p.info {
  margin-top: 0;
  color: var(--text-color);
}
p.info ul {
  list-style-type: none;

  padding: 0;
}
button.open-app-desktop {
  color: var(--text-color);
  border-color: var(--text-color);
  border-width: 1px;
}
button.open-app-desktop:hover {
  border-width: 1px;
}
</style>
