<template>
  <div class="team-logo">
    <img v-if="teamLogoURL" :src="teamLogoURL" class="team-logo">
  </div>
</template>

<script>
import TeamMixin from "../mixins/TeamMixin.vue";
import { AttachmentsMixin } from "@profineberater/configurator-clientlib"

export default {
  name: 'TeamLogo',
  mixins: [TeamMixin, AttachmentsMixin],
  data: function() {

  },
  methods: {
    loadTeamLogoAttachment() {
      this.$store.dispatch("db/getImageAttachment", {
        documentId: "team",
        attachmentId: "teamLogo"
      })
    },
  },
  computed: {
    teamLogoURL() {
      if (this.$store.getters["ui/showUploadedTeamLogo"]) {
        let uploadedLogo = this.$store.getters["ui/uploadedTeamLogo"]
        if (uploadedLogo) {
          var urlCreator = window.URL || window.webkitURL
          return urlCreator.createObjectURL(uploadedLogo)
        } else {
          return require("@/assets/img/placeholder-teamlogo@2x.png")
        }
      }
      return this.attachments["teamLogo"] || require("@/assets/img/placeholder-teamlogo@2x.png")
    }
  },
  mounted() {
    this.loadTeamLogoAttachment()
  },
  watch: {
    team() {
      this.loadTeamLogoAttachment()
    }
  }
}
</script>

<style scoped>
img.team-logo {
  max-height: 512px;
  max-width: 180px;
  margin-top: 20px;
}
</style>
