<template>
  <div class="overview-widget">
    <div class="column illustration">
      <img src="@/assets/svg/illustration-konfigurator.svg">
    </div>
    <div class="column projects">
      <h5>Projekte</h5>
      <span class="subtitle">Überblick über die Anzahl Ihrer Projekte, Konfigurationen, Fotos und Skizzen.</span>
      <div>
        <router-link to="/projects">Projekte anzeigen</router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OverviewWidget",
  props: {},
  components: {
  },
  data: function () {
    return {};
  },
  created() {
  },
  methods: {
  },
  computed: {
  }
};
</script>

<style scoped>
.overview-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.column {
  padding: 20px;
}
h5 {
  text-align: left;
}
.subtitle {
  color: var(--text-color);
  display: block;
  margin: 0 0 20px 0;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .overview-widget {
    flex-direction: column;
  }
}
</style>
