<template>
  <div class="company-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <TeamLogo />
            <h4>
              {{ companyName || 'Firmenname' }}
            </h4>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
            <button class="button primary tall"
              :disabled="isSaving || !canManageCompany || saveButtonDisabled || !validates"
              @click="onSaveTeamCompany">Speichern
            </button>
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import TeamMixin from "../mixins/TeamMixin.vue"
import TeamLogo from "./TeamLogo.vue";

export default {
  name: 'CompanySidebar',
  mixins: [TeamMixin],
  components: {
      SidebarContent,
      TeamLogo,
  },
  data: function() {
    return {
      isSaving: false,
    }
  },
  methods: {
    async onSaveTeamCompany() {
      try {
        this.isSaving = true
        let teamLogo = this.$store.getters["ui/uploadedTeamLogo"]
        if (teamLogo || this.$store.getters["ui/deleteTeamLogoPending"]) {
          await this.$store.dispatch("team/setTeamLogo", teamLogo)
        }
        await this.$store.dispatch("team/saveTeamCompany")
        this.$store.commit("team/companyDetailsNeedsSave", false)
        this.$store.dispatch("user/fetchProfile")
        this.$store.dispatch("team/fetchTeam")
        this.isSaving = false
      } catch (err) {
        if (err.response?.data?.error == "LIMIT_FILE_SIZE") {
          this.$store.commit("ui/error", "Die Datei ist zu groß. Bitte wählen Sie eine kleinere Datei aus.")
          this.$store.commit("team/companyDetailsNeedsSave", true)
          this.isSaving = false
          return
        }
        this.$store.commit("ui/error", "Beim Speichern der Firmendaten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.")
        this.$store.commit("team/companyDetailsNeedsSave", true)
        console.error("Failed to save team company", err)
        this.isSaving = false
      }
    },
  },
  computed: {
    saveButtonDisabled() {
      return this.$store.getters["team/companyDetailsNeedsSave"] === false
    },
    team() {
      return this.$store.getters["team/team"]
    },
    validates() {
      return this.$store.getters["ui/companyValidationModel"].validates
    },
  },
  created() {
    this.$store.dispatch("team/fetchTeam")
  }
}
</script>

<style scoped>
.company-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}

.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
