<template>
  <div class="legal-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Rechtliches
            </h1>
            <div class="subtitle">Alle Dokumente zum Thema Datenschutz und Sicherheit sowie zur Auftrags&shy;verarbeitung sind hier gesammelt.</div>
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'LegalSidebar',
  components: {
      SidebarContent,
  },
}
</script>

<style scoped>
.legal-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
