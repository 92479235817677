<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <BackNavigationItem :backNavigation="{ title: 'Zurück', path: '/legal' }" />
      <EndUserPrivacyTemplateDocument />
    </div>
  </div>
</template>

<script>

import {
  EndUserPrivacyTemplateDocument
} from "@profineberater/configurator-clientlib"
import BackNavigationItem from "../components/ui/BackNavigationItem.vue";

export default {
  name: 'EndUserPrivacyTemplatePage',
  components: {
    EndUserPrivacyTemplateDocument,
    BackNavigationItem
  }
}
</script>
