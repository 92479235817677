<template>
  <div class="fullframe-page-container">
    <div class="second-bg">
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  name: 'FullFramePageContainer',
  props: {
  }
}
</script>

<style scoped>
.fullframe-page-container {
  height: 100vh;
  background: var(--page-bg-color);
}
.second-bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
    position: absolute;
    top: 50px;
    right: 68px;
}
</style>
