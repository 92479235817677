
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <div class="remove-team">
        <h2>Team löschen?</h2>
        {{  deleteConfirmationGiven }}
        <p>
          Wenn Sie das Team löschen, haben Sie keinen Zugriff mehr auf die Projekte Ihrer Teammitglieder.<br>
          <b>Dieser Schritt kann nicht rückgängig gemacht werden!</b>
        </p>
        <!-- <h5>Firmendaten</h5>
        <ul>
          <li>Die Firmendaten werden Ihrem persönlichen Profil zugeordnet.</li>
        </ul> -->
        <h5>Mitglieder</h5>
        <ul>
          <li>Mitglieder sind nicht mehr Mitglied ihn ihrem Team.</li>
          <li>Projekte der Mitglieder verbleiben bei den Mitgliedern.</li>
          <li>Ausgeliehene Projekte werden an denjenigen übertragen, der sie ausgeliehen hat.</li>
        </ul>
        Folgende Mitglider verlassen Ihr Team und können danach eigene Teams gründen:
        <TeamMembers />

        <div class="confirmation">
          <div class="toggle">
            <input type="checkbox" v-model="deleteConfirmationGiven">
            <b>Ja, ich möchte das Team löschen</b>
          </div>
          <button class="button primary danger" :disabled="!deleteConfirmationGiven" @click="onDeleteTeam">Team endgültig löschen</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import TeamMembers from "../components/team/TeamMembers.vue";
import UiMixin from "../components/mixins/UiMixin.vue";
import { useUI } from '@profineberater/configurator-clientlib'

export default {
  name: 'TeamPage',
  mixins: [UiMixin],
  components: {
    TeamMembers
  },
  data: function() {
    return {
      deleteConfirmationGiven: false,
    }
  },
  methods: {
    onDeleteTeam() {
      useUI(this.$store).blockUI({ loading: true });
      this.$store.dispatch("team/deleteTeam")
      .then( () => {
        setTimeout( () => {
          this.forceReloadAndRedirect("/")
          useUI(this.$store).unblockUI();
          this.deleteConfirmationGiven = false

        }, 1000)
      }).catch( err => {

        console.error(err)
        setTimeout( () => {
          useUI(this.$store).unblockUI();
          this.deleteConfirmationGiven = false
        }, 500)
        this.$store.commit("ui/error", "Beim Löschen des Teams ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.")
      })
    }
  }
}
</script>

<style scoped>
.remove-team {
  text-align: left;
}
:deep(.m-toggle label) {
  color: #000;
  font-weight: bold;
  font-size: 12px;
}
.confirmation {
  margin: 40px 0;
}
.confirmation button {
  margin-top: 20px;
}
.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.toggle input {
  margin-right: 10px;
}
</style>
