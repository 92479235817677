
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <InPageLoadingIndicator v-if="!pageLoaded" message="Lade Team Daten ..."/>
      <InPageLoadingIndicator v-if="creatingTeam" message="Team wird angelegt ..."/>

      <div v-if="pageLoaded && !creatingTeam">
        <section v-if="hasPendingInvitations">
          <div>
            <h2>Sie sind bereits eingeladen</h2>
            <p>Sie wurden bereits zu einem Team eingeladen. Bitte prüfen Sie Ihre Benachrichtigungen auf dem Dashboard.</p>
          </div>
        </section>

        <section v-if="canCreateTeam">
          <div>
            <h2>Team-Informationen</h2>
            <h3>Firmendaten</h3>
            <CompanyForm ref="companyForm" v-model="company" :editingAllowed="true"></CompanyForm>
             <a v-if="!emptyProfile" @click="onFillCompanyData" class="transfer-profile-link">Firmendaten aus meinem Profil übernehmen</a>
             <span v-else class="empty-profile-message">Keine Firmendaten im Profil gefunden</span>
             <br>
            <button class="button primary" @click="onCreateTeam" :disabled="!validates">Team gründen</button>
          </div>
        </section>

        <section v-if="ownsTeam">
          <div>
            <p>Sie haben bereits ein Team</p>
            <button class="button primary" @click="$router.push('/team')">Team verwalten</button>
          </div>
        </section>

        <section v-if="isMemberOfAnotherTeam">
          <div>
            <h2>Sie sind bereits Mitglied in einem Team</h2>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { CompanyForm } from "@profineberater/configurator-clientlib"
import TeamMixin from '../components/mixins/TeamMixin.vue';
import InPageLoadingIndicator from "../components/ui/InPageLoadingIndicator.vue";
import UiMixin from "../components/mixins/UiMixin.vue";

export default {
  name: 'CreateTeamPage',
  mixins: [TeamMixin, UiMixin],
  components: {
    CompanyForm,
    InPageLoadingIndicator,
  },
  data: function() {
    return {
      emptyProfile: false,
      company: {
        companyName: null,
        streetAndNumber: null,
        zip: null,
        city: null,
        phone: null,
        email: null
      },
      creatingTeam: false,
      error: null
    }
  },
  props: {
  },
  computed: {
    validates() {
      return this.$store.getters["ui/companyValidationModel"].validates
    },
    profile() {
      return this.$store.getters["user/profile"]
    },
  },
  created() {
    this.$store.dispatch("user/fetchProfile").then( () => {
      this.$store.dispatch("team/fetchTeam").then( () => {
        this.pageLoaded = true
      })
    })
  },
  methods: {
    onRejectAllInvitations() {
      this.$store.dispatch("team/rejectAllInvitations")
    },
    onFillCompanyData() {
      let p = this.profile?.user
      this.company.companyName = p.companyName
      this.company.streetAndNumber = p.streetAndNumber
      this.company.zip = p.zip
      this.company.city = p.city
      this.company.phone = p.phone
      this.company.mobile = p.mobile
      this.company.email = p.email
      this.company.country = p.country

      if (Object.values(this.company).filter( v => v?.length > 0 ).length == 0) {
        this.emptyProfile = true
      }
      this.$refs.companyForm.validateForm()
    },
    onCreateTeam() {
      window.$localDBSync?.cancel()
      this.$store.dispatch("team/createTeam", this.company).then(() => {
        this.creatingTeam = true
        setTimeout( () => {
          this.forceReloadAndRedirect("/team")
        }, 1000)
      }).catch( error => {
        this.$store.commit("ui/error", error)
      })
    },
  }
}
</script>

<style scoped>
.main {
  text-align: left;
}
.transfer-profile-link, .empty-profile-message {
  float: right;
  margin: 10px 0 20px 0;
}

@media only screen and (max-width : 1023px) {

}
@media only screen and (min-width : 1024px) {

}

</style>
