<template>
  <div class="widget-row" :style="style">
    <slot name="title"></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WidgetRow',
  props: {
    gridTemplateColumns: String,
    flexGrow: String
  },
  data: function() {
    return {
      numberOfSlotChildren: 0
    }
  },
  computed: {
    style() {
      return {
        gridTemplateColumns: this.gridTemplateColumns,
        flexGrow: this.flexGrow
      }
    }
  },
}
</script>

<style>
.widget-row {
  margin-bottom: 20px;
}
</style>
