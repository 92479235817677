<template>
  <div class="project-item">
    <div class="header" v-if="headerOnly">
      <ul>
        <li><span class="desktop">Kunde / </span>Projekt </li>
        <li>Ersteller</li>
        <li><span v-if="!readOnly && (canAssignProjects && !isAssignmentHidden)">Zuweisen ...</span></li>
        <li v-if="showActionHeader">Aktion</li>
      </ul>
    </div>
    <AccordionTile :class="clazz" v-show="isProjectVisible" :disabled="isEditingDisabled" v-if="project">
      <template #title>
        <div class="title">
          <ul>
            <li>
              <InitialsBadge :user="project.customer" class="medium" />
            </li>
            <li class="dimmable project-name">{{ project.customer.firstName }} {{ project.customer.lastName }}
              <svg v-if="project.owner" class="group-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="currentColor"
                  d="M12.131 6.911A2.127 2.127 0 1 0 9.26 3.772a4.143 4.143 0 0 0-.243-.485 4.258 4.258 0 0 0-.284-.454 3.19 3.19 0 1 1 0 5.016c.105-.145.199-.297.284-.454.092-.156.173-.318.243-.485a2.128 2.128 0 0 0 2.87 0ZM3.33 7.85a3.194 3.194 0 0 0 1.987.682 3.16 3.16 0 0 0 1.95-.682c.288-.222.536-.491.734-.795a3.17 3.17 0 0 0 .506-1.713 3.25 3.25 0 0 0-.502-1.713 3.193 3.193 0 0 0-.734-.795A3.19 3.19 0 1 0 3.33 7.851Zm9.48 1.745h-2.585c.339.305.643.657.879 1.063h1.701c1.175 0 2.127.952 2.127 2.127h-3.249v1.063H16v-1.063a3.19 3.19 0 0 0-3.19-3.19Zm-3.606.532A3.143 3.143 0 0 0 8 9.652a3.159 3.159 0 0 0-.557-.056H3.19A3.19 3.19 0 0 0 0 12.786v1.063h10.633v-1.063a3.171 3.171 0 0 0-.827-2.127 3.28 3.28 0 0 0-.602-.532Z" />
              </svg>
              <ArchiveIcon v-if="project.isArchived" :solid="true" class="archive-icon" />
            </li>
            <li class="dimmable project-owner">
              <span v-html="ownerLabel"></span>
            </li>
            <li class="project-assign">
              <DropDown
                v-if="!readOnly && (canAssignProjects && !isAssignmentHidden)"
                :disabled="isAssignmentDisabled"
                v-model="assignedToId"
                :options="projectOwnerOptions"
                placeholder="Wählen"
                :omitSelectedOptions="true"
                :allowNoSelection="false"
              />
              <span v-else></span>
            </li>
            <li class="actions">
              <button v-show="source == 'my-projects'" :disabled="isEditingDisabled" class="button icon"
                @click.stop.prevent="onProjectOpen(project)">
                <EditProjectIcon />
              </button>
              <button v-show="source == 'my-projects'" :disabled="isEditingDisabled" class="button icon"
                @click.stop.prevent="onProjectOpen(project)">
                <DownloadProjectIcon />
              </button>
              <button v-show="canDeleteProject && source == 'my-projects'" :disabled="isEditingDisabled" class="button icon"
                @click.stop.prevent="onProjectDelete(project)">
                <DeleteProjectIcon />
              </button>
            </li>
          </ul>
        </div>
      </template>
      <template #main v-if="project">
        <div class="project-details">
          <ul v-for="(configuration, index) in project.configurations" v-bind:key="'config_' + index">
            <li class="position-number">{{ configuration.positionNumber }}</li>
            <li class="configuration-title">{{ configuration.title }}</li>
            <li class="configuration-actions">
              <button class="button plain" @click.prevent="downloadConfiguration(configuration, 'csv')">CSV Export</button>
            </li>
          </ul>
          <div v-if="!hasConfigurations">
            <ul>
              <li class="position-number">&nbsp;</li>
              <li class="configuration-title">Keine Konfigurationen.</li>
            </ul>
          </div>
        </div>
      </template>
    </AccordionTile>
  </div>
</template>

<script>
import AccordionTile from "@/components/ui/AccordionTile.vue"
import { InitialsBadge, DropDown, EditProjectIcon, DownloadProjectIcon, DeleteProjectIcon, ArchiveIcon } from "@profineberater/configurator-clientlib"
import download from "downloadjs"
import { Parser } from "json2csv"
import removeAccents from "remove-accents"
import UIMixin from "../mixins/UiMixin.vue"

export default {
  name: 'ProjectItem',
  mixins: [UIMixin],
  components: {
    AccordionTile,
    InitialsBadge,
    DropDown,
    EditProjectIcon,
    DownloadProjectIcon,
    DeleteProjectIcon,
    ArchiveIcon
  },
  props: {
    project: {
      type: Object,
    },
    checkoutInfo: {
      type: Object,
      required: false
    },
    holderId: {
      type: String,
      required: false
    },
    holderName: {
      type: String,
      required: false
    },
    source: { // my-projects, team-projects
      type: String,
    },
    headerOnly: {
      type: Boolean,
      default: false
    },
    showActionHeader: {
      type: Boolean,
      default: true
    },
    team: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    assignedToId: {
      get() {
        return this.checkoutInfo?.assignedTo || this.holderId
      },
      set(newAssigneeId) {
        this.$emit('project:assign', this.assignedToId, newAssigneeId, this.project)
      }
    },
    ownerLabel() {
      if (this.teamEnabled) {
        return (this.project.ownerName ? this.project.ownerName : this.holderName) || this.$store.getters["user/userName"] + " <span class='me'>(Ich)</span>"
      }
      return ""
    },
    projectOwnerOptions() {
      var isMe, isCreator

      let options = []
      if (this.team?.owner?.id) {
        let isMe = this.team?.owner?.id == this.userId
        let isCreator = this.project?.owner == this.team?.owner?.id
        options.push(
          {
            prefix: '<div class="dot user-role-teamleader"></div>',
            label: `${this.team?.owner?.firstName} ${this.team?.owner?.lastName} ${this.labelSuffix(isMe, true, isCreator)}`,
            value: this.team?.owner?.id,
          }
        )
      }

      let members = this.team?.members?.map(member => {
        isMe = member.id == this.userId
        isCreator = this.project?.owner == member.id
        return {
          prefix: `<div class="dot ${member.roles}"></div>`,
          label: `${member.firstName} ${member.lastName} ${this.labelSuffix(isMe, false, isCreator)}`,
          value: member.id
        }
      })
      if (members) {
        options = options.concat(members)
      }
      return options
    },
    hasConfigurations() {
      return this.project?.configurations?.length > 0
    },
    clazz() {
      let s = 'project-item'
      if (this.checkoutInfo) {
        s += ' checked-out'
      }
      // if (this.source == "my-projects") {
      //   s += ' source-is-my-projects'
      // }
      if (this.checkoutInfo?.assignedTo && this.checkoutInfo?.assignedTo != this.$store.getters["user/userId"]) {
        s += ' not-editable'
      }
      return s
    },
    assignedToName() {
      let s = ''
      if (this.checkoutInfo) {
        if (this.checkoutInfo.assignedTo.id == this.$store.getters["user/userId"]) {
          s += 'Mir'
        } else {
          s += `${this.checkoutInfo.assignedToName}`
        }
      }
      return s
    },
    isMyProject() {
      return !this.project?.owner
    },
    isAssignedToMe() {
      return this.checkoutInfo?.assignedTo == this.$store.getters["user/userId"]
    },
    isAssignedToHolder() {
      return this.checkoutInfo?.assignedTo == this.holderId
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
    isAssigned() {
      return this.checkoutInfo != null
    },
    isAssignmentDisabled() {
      //return this.source == "my-projects" &&
      return (this.isAssigned && !this.isAssignedToHolder)
    },
    isAssignmentHidden() {
      return this.isAssignmentDisabled
    },
    isEditingDisabled() {
      if (this.source == "my-projects") {
        return this.isAssigned && !this.isAssignedToMe
      }
      return true
    },
    canAssignProjects() {
      return this.$store.getters["team/canEditTeamMemberProjects"]
    },
    userId() {
      return this.$store.getters["user/userId"]
    },
    memberName() {
      return this.member?.holderName || "Ich"
    },
    isProjectVisible() {
      return this.holderId == this.assignedToId
    },
    canDeleteProject() {
      return this.isMyProject || this.canAssignProjects
    }
  },
  methods: {
    labelSuffix(isMe, isTeamLeader, isCreator) {
      let s = ""
      if (isMe) {
        s = "(Ich"
      } else if (isTeamLeader) {
        s += "(Teamleiter"
      }
      if (isCreator) {
        s += (s.startsWith("(") ? ", " : "(") + "Ersteller"
      }
      if (s.startsWith("(")) {
        s += ")"
      }
      return s
    },
    onProjectOpen(project) {
      window.open(`${process.env.VUE_APP_CONFIGURATOR_APP_URL}/project?p=${project._id}&embedded=true`)
    },
    onProjectDelete(project) {
      this.$emit('project:delete', project)
    },
    delim(project, index) {
      return (index < project?.configurations?.length - 1) ? ', ' : ''
    },
    csvFromJson(json) {
      const fields = Object.keys(json)
      const header = true
      const opts = { fields, header };

      try {
        const parser = new Parser(opts);
        const csv = parser.parse(json);
        return csv
      } catch (err) {
        console.error(err);
      }
      return null
    },
    downloadConfiguration(configuration, type) {
      let csv = this.csvFromJson(configuration)
      if (type == "json") {
        let json = JSON.stringify(configuration, null, 2)
        json = removeAccents(json) // FIXME: charset not right, in JSON when downloaded?
        download(json, `config_${configuration.title}.json`, 'application/json')
      } else if (type == "csv") {
        download(csv, `config_${configuration.title}.csv`, "text/csv")
      }
      //  let win = window.open()
      //  win.document.write(csv || "CSV konnte nicht generiert werden.")
    },
    stats(project) {
      let configCount = project.configurations?.length || 0
      let scribblesCount = project.scribbles?.length || 0
      let notesCount = project.notes?.length || 0
      let photosCount = project.photos?.length || 0
      let texts = []
      switch (configCount) {
        case 0: texts.push("Keine Konfigurationen"); break;
        case 1: texts.push(`1 Konfiguration`); break;
        default: texts.push(`${configCount} Konfigurationen`); break;
      }
      switch (scribblesCount) {
        case 0: texts.push("Keine Zeichnungen"); break;
        case 1: texts.push(`1 Zeichnung`); break;
        default: texts.push(`${scribblesCount} Zeichnungen`); break;
      }
      switch (notesCount) {
        case 0: texts.push("Keine Notizen"); break;
        case 1: texts.push(`1 Notiz`); break;
        default: texts.push(`${notesCount} Notizen`); break;
      }
      switch (photosCount) {
        case 0: texts.push("Keine Fotos"); break;
        case 1: texts.push(`1 Foto`); break;
        default: texts.push(`${photosCount} Fotos`); break;
      }
      return texts
    },
  }
}
</script>

<style scoped>
.project-details {
  margin: 20px 0 20px 0;
}

.project-name {
  color: #000;
  font-weight: 700;
  font-size: 14px;
}

.project-owner {
  color: var(--text-color);
  font-weight: 500;
}

.accordion-collapsed {
  height: 42px;
}

:deep(.accordion-title) {
  padding: 0;
}

.accordion {
  border-bottom: 1px solid #EDEFF4;
  margin-bottom: 0;
}

.accordion:last-child {
  border-bottom: none;
}

ul {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
  font-weight: 300;
  font-size: 14px;
  color: var(--text-color);
}

li:first-child {
  width: 59px;
}

li:nth-child(2) {
  width: 200px;
  /* padding-right: 20px; */
  word-break: break-all;
}

li:nth-child(3) {
  width: 230px;
}
li:nth-child(4) {
  width: 300px;
  padding-right: 20px;
}
li:nth-child(5) {
  width: 180px;
  text-align: left;
}

button.button.icon {
  background-color: rgb(246, 247, 249);
  width: 32px;
  height: 32px;
  margin-right: 4px;
  padding: 0;
  color: var(--primary-color);
}
button.button.icon svg {
  transform: translateX(8px);
}

button.button.icon:hover {
  color: #fff;
  background-color: var(--primary-color);
}
li.configuration-actions {
  text-align: right;
  padding-right: 0px;
}

li.position-number {
  padding-left: 50px;
  color: #B5C0D1;
  font-weight: 700;
  width: 80px;
}

li:nth-child(5) {
  display: none;
}

.header {
  padding: 20px 0 20px 20px;
}

.header li {
  color: var(--darkest-text-color);
  font-size: 12px;
  font-weight: 700;
}

.header li:first-child {
  width: calc(200px + 59px);
}

.header li:nth-child(2) {
  width: 230px;
}

.header li:nth-child(3) {
  width: 300px;
}

.header li:nth-child(4) {
  width: 200px;
}

li.configuration-title {
  color: #3F516A;
  font-weight: 500;
  width: 480px;
}

.title {
  padding: 5px 20px;
}

:deep(.accordion-action) {
  margin-right: 40px;
}

:deep(span.me) {
  color: var(--primary-color);
}
.not-editable li.dimmable {
  opacity: 1;
}

.not-editable {
  /* background-color: rgb(120, 130, 168, 0.05); */
}
.project-owner span {
  display: block;
}

:deep(.selectbox) {
  height: 32px;
  border: 1px solid #E4E7ED;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M9.133 3.857 6.027.751l-3.16 3.16M2.867 8.143l3.106 3.106 3.16-3.16' style='fill:none;stroke:%23181d29;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

:deep(.dropdown) {
  margin-bottom: 0;
  height: 32px;
}

.group-icon {
  color: var(--primary-color);
}

:deep(.dot) {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
:deep(.dot.user-role-teamleader) {
  background-color: var(--color-role-teamleader);
}

:deep(.dot.user-role-manager) {
  background-color: var(--color-role-manager);
}

:deep(.dot.user-role-staff) {
  background-color: var(--color-role-staff);
}

.archive-icon {
  display: inline-block;
  margin-left: 4px;
  color: var(--primary-color);
}

/* media query for phone */
@media (max-width: 854px) {
  span.desktop {
    display: none;
  }
  div.project-item div.accordion div.accordion-title div.title ul {
    width: 100%;
  }
  li {
    word-break: break-word;
  }
  li:first-child {
    width: 50px;
  }
  li:nth-child(4) {
    width: 100px;
    padding-right: 0px;
  }
  li.project-name {
    display: none;
  }
  li.project-owner {
    width: 100px;
    padding-right: 10px;
  }
  li.project-assign {
    width: 117px;
  }
  li.actions {
    display: none;
  }
  li.position-number {
    padding-left: 0px;
  }
  :deep(.accordion-action) {
    margin-right: 30px;
  }
  .header ul {
    width: 80vw;
  }
  .header li:first-child {
    width: 50px;
  }
  .header li:nth-child(2) {
    width: 100px;
  }
  .header li:nth-child(3) {
    width: 120px;
  }
  .header li:nth-child(4) {
    display: none;
  }
  :deep(.dropdown) {
    min-width: 120px;
    max-width: 120px;
  }
  :deep(.dropdown .selectbox) {
    min-width: 120px;
    max-width: 120px;
  }
  .accordion-collapsed {
    height: auto;
  }
  div.project-item {
    width: 90vw;
  }
  div.project-details {
    margin: 0;
    width: 75vw;
  }
  div.accordion.project-item div.slot {
    padding: 0 0 0 25px;
  }
  div.accordion.project-item div.slot div.project-details ul {
    width: 90%;
  }
  div.accordion.project-item div.slot div.project-details ul li {
    width: unset;
    padding: 5px 0;
  }
}
@media (min-width: 855px) and (max-width: 1024px) {
  .header li:first-child {
    width: 130px;
  }
  .header li:nth-child(2) {
    width: 90px;
  }
  .header li:nth-child(3) {
    width: 130px;
  }
  .header li:nth-child(4) {
    display: none;
  }
  div.accordion-title div.title {
    width: 90%;
  }
  :deep(.accordion-action) {
    margin-right: 30px;
  }
  div.project-item ul {
    width: 85vw;
  }
  div.accordion.project-item div.slot div.project-details ul {
    width: 90%;
  }
  :deep(.dropdown) {
    min-width: 120px;
    max-width: 200px;
  }
  :deep(.dropdown .selectbox) {
    min-width: 120px;
    max-width: 200px;
  }
}
@media (min-width: 1025px) {
  li:nth-child(5) {
    display: block ;
  }
}
@media (max-width: 1280px) {
  .scrollable-container.main {
    width: 100vw;
  }
}
</style>
