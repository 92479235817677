<template>
  <div class="welcome-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <WelcomeText />
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
            <router-link to="/apps">
              <button type="button" class="button primary">App installieren</button>
            </router-link>
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import WelcomeText from "../welcome/WelcomeText.vue";
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'WelcomeSidebar',
  components: {
    SidebarContent,
    WelcomeText
  },
}
</script>

<style scoped>
.welcome-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.copy-check {
  transition: all 0.5 ease-in-out;
  color: var(--primary-color);
  font-weight: 500;
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.copy-app-link {
  height: 40px;
}

</style>
