<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <BackNavigationItem :backNavigation="{ title: 'Zurück', path: '/legal' }" />
      <PrivacyDocument />
    </div>
  </div>
</template>

<script>

import {
  PrivacyDocument
} from "@profineberater/configurator-clientlib"
import BackNavigationItem from "../components/ui/BackNavigationItem.vue";

export default {
  name: 'PrivacyPage',
  components: {
    PrivacyDocument,
    BackNavigationItem
  },
}
</script>
