<template>
  <div class="welcome-text">
    <h1>
      Willkommen in Ihrem profine eSolutions Konto.
    </h1>
    <div class="subtitle">Hier befinden Sie sich im Dashboard des Admin-Bereiches.</div>
  </div>
</template>

<script>

export default {
  name: 'WelcomeText',
}
</script>

<style scoped>
.welcome-text {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
