<template>
  <div class="profile-widget">
    <InitialsBadge :user="user" placeholder="FB"/>
    <h4 v-if="user.companyName">{{ user.companyName }}</h4>

    <p v-if="user.firstName && user.lastName" class="name">
      {{ user.firstName }} {{ user.lastName }}
    </p>

    <p v-if="user.streetAndNumber && user.zip && user.city" class="address">
      {{ user.streetAndNumber }}<br>
      {{ user.zip }} {{ user.city }}
    </p>

    <p  v-if="user.phone && user.mobile" class="phone">
      Telefon {{ user.phone }}<br>
      Mobil {{ user.mobile }}
    </p>

    <p v-if="user.phone && user.mobile" class="contact">
      <a :href="'mailto:'+user.email">{{ user.email }}</a><br>
      <a :href="user.website" target="_blank">{{ user.website }}</a>
    </p>

    <div class="actions">
      <router-link to="/profile">
        <button type="button" class="button rounded ghost">
          Profil bearbeiten
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>

import { InitialsBadge } from "@profineberater/configurator-clientlib"

export default {
  name: 'ProfileWidget',
  components: {
    InitialsBadge
  },
  props: {
  },
  computed: {
    profile() {
      return this.$store.getters["user/profile"]
    },
    user() {
      return this.profile.user
    },
    missingProfileInfos() {
      let missing = []
      return missing
    }
  },
}
</script>

<style scoped>
.profile-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 40px;
}
.actions {
  margin-top: 40px;
}
p {
  color: var(--text-color);
  font-size: 13px;
}
:deep(.initials-badge) {
  min-width: 163px;
  min-height: 163px;
  max-width: 163px;
  max-height: 163px;
  font-size: 36px;
  border-radius: 82px;
}

@media only screen and (max-width : 1280px) {

}
</style>
