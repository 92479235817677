<template>
  <div class="sidebar-content">
    <div class="content">
        <slot name="content"></slot>
    </div>
    <div class="sidebar-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarContent',
  props: {
  }
}
</script>

<style scoped>
.sidebar-content {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-grow: 1;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-content .content {
  margin-bottom: 10px;
}

.sidebar-content .content::before {
  margin-bottom: 10px;
}

.sidebar-content.noactions .content::before {
 height: 20px;
}

.sidebar-content.noactions .sidebar-actions {
  visibility: hidden;
}


</style>

<style>
.sidebar-actions {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.sidebar-actions button {
    margin-bottom: 20px;
}
.sidebar-actions button:last-child {
  margin-bottom: 0;
}
</style>
