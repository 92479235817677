<template>
  <div :class="'container ' + (isInfoBarShown ? 'info-shown' : '') + ($store.state.ui.isScreenBlocked ? ' dimmer' : '')">
    <LoadingSpinner v-if="$store.state.ui.isScreenBlocked && isLoading" />

    <div class="main-layout">
      <div class="sidebar-contents">
        <div class="info">
          <InfoSidebar />
        </div>
        <div class="sidebar">
          <InspectorSidebar>
            <router-view name="sidebar"/>
          </InspectorSidebar>
        </div>
      </div>
      <div class="main-contents">
        <div :class="'header' + ($store.getters['ui/debug'] ? ' debug' : '')">
          <MainHeader />
        </div>

        <div :class="'main ' + ($store.state.ui.isMasterViewBlocked ? 'dimmer' : '')" @contextmenu:toggle.stop="$refs.contextMenu.toggle($event.target)" @click="onDimmerClick">
          <div class="main-header" v-show="false"></div>
          <router-view />
          <LoadingSpinner v-if="$store.state.ui.isMasterViewBlocked && isLoading" />
        </div>
      </div>
    </div>
    <ContextMenu ref="contextMenu" />
    <img class="button burger" src="@/assets/svg/burger.svg" @click="onBurgerClick">
    <transition name="fade">
      <MenuOverlayPage v-show="$store.state.ui.isSiteMenuShown" />
    </transition>
    <ErrorMessage v-if="$store.state.ui.error" />
  </div>
</template>

<script>
import InfoSidebar from "./components/sidebar/InfoSidebar.vue"
import MainHeader from "./components/nav/MainHeader.vue"
import InspectorSidebar from "./components/sidebar/InspectorSidebar.vue"
import LoadingSpinner from "./components/ui/LoadingSpinner.vue"
import ContextMenu from "@/components/ui/ContextMenu.vue"
import UiMixin from "@/components/mixins/UiMixin.vue"
import MenuOverlayPage from "@/views/MenuOverlayPage.vue"
import { useUI, ErrorMessage } from '@profineberater/configurator-clientlib'

export default {
  name: 'AppLayout',
  mixins: [UiMixin],
  components: {
    MainHeader,
    InfoSidebar,
    InspectorSidebar,
    LoadingSpinner,
    ContextMenu,
    MenuOverlayPage,
    ErrorMessage,
  },
  methods: {
    onDimmerClick(e) {
      if (this.$store.state.ui.isUIUnblockByUserDisabled) {
        e.stopPropagation()
        return
      }
      if (this.isMainViewBlocked) {
        e.stopPropagation()
      }
      if (!this.isLoading) {
        useUI(this.$store).unblockUI();
      }
    },
    onBurgerClick() {
      this.$store.commit("ui/isSiteMenuShown", true)
    }
  },
  computed: {
    isInfoBarShown() {
      return this.$store.state.info.isInfoBarShown
    },
    isLoading() {
      return this.$store.state.ui.isLoading
    },
    isMainViewBlocked() {
      return this.$store.state.ui.isMainViewBlocked
    },
    isScreenBlocked() {
      return this.$store.state.ui.isScreenBlocked
    },
    lastFullSyncContentDB() {
      return this.$store.getters["user/lastFullSyncContentDB"]
    }
  },
  created() {
    this.$store.commit("ui/isMasterViewBlocked", false)
  },
  mounted() {
    if (this.lastFullSyncContentDB != null) {
        this.$store.dispatch("configurationOptions/fetchAllOptions");
    }
  },
  watch: {
    lastFullSyncContentDB(newDate, oldDate) {
      if (oldDate == null) {
        this.$store.dispatch("configurationOptions/fetchAllOptions");
      }
    }
  }
}
</script>

<style scoped>

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.main-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.container.info-shown {
  /* grid-template-columns: 1fr 385px var(--sidebar-width);
  grid-template-rows: 86px 1fr;
  grid-template-areas:
    "header info sidebar"
    "main info sidebar"; */
}
.sidebar-contents {
  grid-area: sidebar;
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  max-width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}
.info-shown .sidebar-contents {
  min-width: calc(var(--sidebar-width) + var(--infobar-width));
  max-width: calc(var(--sidebar-width) + var(--infobar-width));
  transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}
.sidebar {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-basis: var(--sidebar-width);
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
}
.header {
  grid-area: header;
  overflow: hidden;
}
.info-shown .info {
  min-width: var(--infobar-width);
  max-width: var(--infobar-width);
  transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}
.info {
  grid-area: info;
  background: var(--c-white-grey);
  display: flex;
  min-width: 0;
  max-width: 0;
  overflow: hidden;
  transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out;
}
.main-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 70px;
}
.main-contents {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 86px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "main";
  flex-grow: 1;
}
.main {
  /* grid-area: main; */
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: relative;
  padding: 20px 10px;
  flex-grow: 1;
}

.button.burger {
  position: fixed;
  top: 30px;
  right: 40px;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width : 1280px) {
  .sidebar-contents {
    display: none;
  }
  .button.burger {
    display: block;
  }
  .main {
    padding: 0;
  }
}

</style>
