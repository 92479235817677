<template>
  <div :class="'main-header ' + ($store.state.ui.isMasterViewBlocked ? 'dimmer' : '')">
    <div class="left">
      <MainNav class="top-navigation" />
    </div>
    <div class="center">
    </div>
    <div class="right">
    </div>
  </div>
</template>

<script>
import MainNav from "./MainNav.vue"

export default {
  name: 'MainHeader',
  components: {
    MainNav,
  },
  props: {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
}
.main-header {
  padding: 40px 80px 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  position: relative;

}
nav {
  display: flex;
  flex-direction: column;
}
.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nav-inner {
  background-color: aqua;
}
.item {
  cursor: pointer;
  color: var(--c-greyish);
  letter-spacing: 2px;
  font-size: 14px;
  width: 120px;
}
.item.active {
  color: #000;
}
.active-indicator {
  background-color: var(--c-button-blue);
  height: 2px;
  width: 42px;
}
.left {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
.right {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.center {
  display: flex;
  align-items: center;
}

.top-navigation {
  margin-left: 30px;
}

@media only screen and (max-width : 1280px) {
  .top-navigation {
    display: none;
  }
}

</style>
