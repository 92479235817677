<template>
  <div class="upload-team-logo-form">
    <form>
      <input ref="uploadImageInput"
        type="file"
        id="picture"
        class="upload-image-input"
        accept="image/png, image/jpeg"
        @change="handleUpload"
      >

      <table class="ios-style" v-if="team">
        <tr>
          <td class="cursor-pointer noselect">Firmenlogo</td>
          <td>
            <button v-show="showDeleteButton" type="button" class="button rounded ghost clear" @click="onRemove">Entfernen</button>
            <button type="button" class="button rounded ghost add" @click="onAdd">Auswählen</button>
          </td>
        </tr>
      </table>
    </form>
    <legend class="mandatory">
      JPG oder PNG, max. 0.5 MB / 512 x 512 Pixel
    </legend>
  </div>
</template>

<script>

import TeamMixin from '../mixins/TeamMixin.vue';

export default {
  name: "UploadTeamLogoForm",
  mixins: [TeamMixin],
  methods: {
    onAdd() {
      this.$refs.uploadImageInput.click();
    },
    onRemove() {
      this.$store.dispatch("team/removeTeamLogo", this.team)
      this.$store.commit("ui/uploadedTeamLogo", null)
      this.$store.commit("ui/showUploadedTeamLogo", true)
      this.$emit("logo:removed")
    },
    handleUpload(e) {
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      console.log(file)
      reader.onload = () => {
        this.$store.commit("ui/uploadedTeamLogo", file)
        this.$store.commit("ui/showUploadedTeamLogo", true)
        this.$emit("logo:added", file)
      }
    },
  },
  computed: {
    showDeleteButton() {
      return this.hasTeamLogo
    }
  }
};
</script>

<style scoped>
button.button:first-of-type {
  margin-right: 10px;
  border-color: #b5c0d1;
  color: #000b17;
}

button.button:first-of-type:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

button.button {
  height: 34px;
}
.upload-image-input {
  display: none;
}
img.team-logo {
  height: 60px;
}

.mandatory {
  text-align: left;
  padding: 20px 20px;
  color: var(--text-color);
  font-size: 12px;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 526px) {
  button.button:first-of-type {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 531px) {
  button.button:first-of-type {
    margin-right: 5px;
  }
  div.upload-team-logo-form table.ios-style tr td:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>

