<template>
  <div class="latest-projects">
    <div class="content">
      <ProjectItem :readOnly="true" v-for="(project, index) in projects" v-bind:key="'project_' + index" :project="project" />
      <div class="footer">
        <router-link v-if="projects.length > 0" to="/projects">Alle ansehen</router-link>
      </div>
      <div v-if="projects.length == 0" class="no-projects">
        Sie haben noch keine Projekte. Installieren Sie die <router-link to="/apps">Framework App</router-link>, um zu starten!
      </div>
    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue";
import ProjectItem from "../projects/ProjectItem.vue";

export default {
  name: "LatestProjects",
  mixins: [UiMixin],
  props: {},
  components: {
    ProjectItem
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("project/getAll").then( () => {
      this.loaded = true
    })
  },
  computed: {
    projects() {
      return this.$store.state.project.projects.slice(0, 3)
    },
    contextMenuItems() {
      return [
        {
          title: "Alle ansehen",
          handler: () => {
            this.$router.push("/logs");
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.latest-projects {
}

h3 {
  font-size: 18px;
}

th.title {
  text-align: left;
}
th.header-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

td.view-all {
  text-align: center;
  height: 40px;
  border: none;
}

.no-projects {
  margin-left: 20px;
}
.footer {
  margin-top: 20px;
}
</style>
