<template>
  <div></div>
</template>
<script>

export default {
  computed: {
    team() {
      return this.$store.getters["team/team"]
    },
    teamName() {
      return this.team?.name
    },
    isMemberOfAnotherTeam() {
      return this.$store.getters["team/isMemberOfAnotherTeam"]
    },
    ownsTeam() {
      return this.$store.getters["team/ownsTeam"]
    },
    noTeam() {
      return !this.team && !this.isMemberOfAnotherTeam && !this.ownsTeam
    },
    canManageCompany() {
      return this.$store.getters["team/canManageCompany"]
    },
    canManagePayment() {
      return this.$store.getters["team/canManagePayment"]
    },
    isTeamLeader() {
      return this.$store.getters["team/isTeamLeader"]
    },
    isManager() {
      return this.$store.getters["team/isManager"]
    },
    canManageUsers() {
      return this.$store.getters["team/canManageUsers"]
    },
    canReadTeamMemberProjects() {
      return this.$store.getters["team/canReadTeamMemberProjects"]
    },
    canEditTeamMemberProjects() {
      return this.$store.getters["team/canEditTeamMemberProjects"]
    },
    canInviteUsers() {
      return this.$store.getters["team/canInviteUsers"]
    },
    canCreateTeam() {
      return this.$store.getters["team/canCreateTeam"]
    },
    hasPendingInvitations() {
      return this.$store.getters["team/hasPendingInvitations"]
    },
    projectsCheckoutMap() {
      return this.team?.projects || {}
    },
    companyName() {
      return this.team?.company?.companyName
    },
    hasTeamLogo() {
      return this.team?._attachments?.teamLogo != undefined
    }
  },
  methods: {
    lentProjects(memberId, projects) {
      return projects.filter( p => (this.projectsCheckoutMap[p._id] && this.projectsCheckoutMap[p._id]?.assignedTo != memberId ) )
    },
    assignedProjectIds(memberId) {
      return Object.keys(this.projectsCheckoutMap).filter( key => {
        return this.projectsCheckoutMap[key].assignedTo == memberId
      })
    },
    availableProjects(memberId, projects) {
      return projects.filter( p => this.projectsCheckoutMap[p._id] == null || (this.projectsCheckoutMap[p._id] && this.projectsCheckoutMap[p._id]?.assignedTo == memberId ) )
    },
    lentProjectsInfo(memberId, projects) {
      const lentProjects = this.lentProjects(memberId, projects)
      if (lentProjects.length == 0) {
        return ""
      } else if (lentProjects.length == 1) {
        return `Ein Projekt ist in Berarbeitung von ${this.projectsCheckoutMap[lentProjects[0]._id].assignedToName}.`
      } else {
        return `${lentProjects.length} Projekte sind in Berarbeitung von anderen Mitarbeitern.`
      }
    },
  }
}
</script>
