
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <p v-if="!canManageCompany" class="insufficient-rights-notice">
        Hinweis: Firmendaten können nur von einem Teamleiter oder Manager gepflegt werden.
      </p>
      <h4>Ihre Firmendaten</h4>
      <CompanyForm v-if="company" ref="companyForm" :editingAllowed="canManageCompany" v-model="company" @validation:result="onValidationResult"/>
      <h4 v-if="canManageCompany">Ihr Logo</h4>
      <TeamLogo class="mobile" />
      <UploadTeamLogoForm v-if="canManageCompany" @logo:added="onLogoChange" @logo:removed="onLogoDelete"/>
      <div class="action-buttons mobile">
        <button class="button primary tall"
          :disabled="isSaving || !canManageCompany || saveButtonDisabled || !validates"
          @click="onSaveTeamCompany">Speichern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CompanyForm } from "@profineberater/configurator-clientlib"
import TeamMixin from "../components/mixins/TeamMixin.vue"
import UiMixin from "../components/mixins/UiMixin.vue"
import UploadTeamLogoForm from "../components/team/UploadTeamLogoForm.vue"
import TeamLogo from "../components/sidebar/TeamLogo.vue"

export default {
  name: 'CompanyPage',
  mixins: [TeamMixin, UiMixin],
  components: {
    CompanyForm,
    UploadTeamLogoForm,
    TeamLogo,
  },
  data: function() {
    return {
      isSaving: false
    }
  },
  computed: {
    saveButtonDisabled() {
      return this.$store.getters["team/companyDetailsNeedsSave"] === false
    },
    company: {
      get() {
        return this.team ? this.team.company : this.profile?.company
      },
      set(company) {
        if (this.team) {
          this.$store.commit("team/company", company)
        } else {
          this.profile.company = company
          this.$store.commit("user/profile", this.profile)
        }
      }
    },
    team() {
      return this.$store.getters["team/team"]
    },
    profile() {
      return this.$store.getters["user/profile"]?.user
    },
    validates() {
      return this.$store.getters["ui/companyValidationModel"].validates
    },
  },
  methods: {
    async onSaveTeamCompany() {
      try {
        this.isSaving = true
        await this.$store.dispatch("team/saveTeamCompany")
        this.$store.commit("team/companyDetailsNeedsSave", false)
        this.$store.dispatch("user/fetchProfile")
        this.$store.dispatch("team/fetchTeam")
        this.isSaving = false
      } catch (err) {
        this.$store.commit("ui/error", "Beim Speichern der Firmendaten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.")
        console.error("Failed to save team company", err)
        this.$store.commit("team/companyDetailsNeedsSave", true)
        this.isSaving = false
      }
    },
    onLogoChange() {
      this.$refs.companyForm.validateForm()
    },
    onLogoDelete() {
      this.$store.commit("ui/deleteTeamLogoPending", true)
      this.$refs.companyForm.validateForm()
      this.$store.commit("team/companyDetailsNeedsSave", true)
    },
    onValidationResult(result) {
      this.$store.commit("team/companyDetailsNeedsSave", result.validates === true)
    }
  },
  created() {
    this.$store.dispatch("user/fetchProfile")
    this.$store.dispatch("team/fetchTeam")
  },
}
</script>
<style scoped>
.team-form {
  margin-bottom: 40px;
}

h4 {
  text-align: left;
}
.action-buttons.mobile,
.team-logo.mobile {
  display: none;
}

@media only screen and (max-width : 1280px) {
  .action-buttons.mobile {
    display: block;
    margin-bottom: 20px;
  }
  .team-logo.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>

