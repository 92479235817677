<template>
  <div class="team-members-list-view">
      <form v-if="!headerOnly">
        <table v-if="team && team.members" class="ios-style">
          <tr>
            <th class="noselect">Name</th>
            <th class="select">Email</th>
            <th v-if="editable" class="noselect">Rolle</th>
            <th v-if="editable && canRemoveMembers" class="noselect">Aktionen</th>
          </tr>
          <tr>
            <td class="team noselect roles">
              <span class="member-name teamleader">{{ teamOwnerName }}<span class="me" v-if="isIamTeamLeader"> (Ich)</span></span>
              <RolesBadges :roles="['user-role-teamleader']" />
            </td>
            <td class="team noselect email teamleader">{{ teamOwnerEmail }}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr v-for="(member, index) in team.members" v-bind:key="index">
            <td class="team noselect roles">
              <span class="member-name">{{ member.firstName }} {{ member.lastName }} <span class="me" v-if="isMe(member)">(Ich)</span></span>

              <RolesBadges :roles="member.roles" />
            </td>
            <td class="team noselect">{{ member.email }}</td>
            <td v-if="editable" class="team roles noselect">
              <DropDown
                :disabled="roleDropdownDisabled(member)"
                class="role-dropdown"
                :modelValue="userToRolesMapping[member.id]"
                @update:modelValue="$emit('role:change', $event, member, member.roles)"
                :options="roleOptions.values"
                :placeholder="roleOptions.placeholder"
                :allowNoSelection="false"
              />
            </td>
            <td v-if="editable && canRemoveMembers" class="team noselect">
              <button
                :disabled="assignedProjectIds(member.id).length > 0"
                class="button plain mediumsize danger"
                @click.prevent="$emit('remove:member', member.id)">
                <DeleteIcon />
              </button>
            </td>
          </tr>
          <tr v-if="team && team.members.length == 0">
            <td colspan="4">Keine Mitglieder im Team.</td>
          </tr>
        </table>
      </form>
  </div>
</template>

<script>

import RolesBadges from "@/components/user/RolesBadges.vue"
import { DropDown, DeleteIcon } from "@profineberater/configurator-clientlib"
import TeamMixin from "../mixins/TeamMixin.vue";

export default {
  name: "TeamMembersListView",
  mixins: [TeamMixin],
  components: {
    RolesBadges,
    DropDown,
    DeleteIcon
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    headerOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    roleDropdownDisabled(member) {
      return member.id == this.$store.getters["user/userId"]
    },
    isMe(member) {
      return member.id == window.$keycloak?.idTokenParsed?.sub
    }
  },
  computed: {
    roleOptions() {
      return {
        placeholder: "Rolle zuweisen...",
        values: [
          {
            label: "Mitarbeiter",
            value: "user-role-staff",
          },
          {
            label: "Manager",
            value: "user-role-manager"
          },
        ]
      }
    },
    userToRolesMapping() {
      return this.team?.members?.reduce( (mapping, member) => {
        mapping[member.id] = member.roles[0]
        return mapping
      }, {}) || {}
    },
    canRemoveMembers() {
      return this.$store.getters["team/canRemoveMembers"]
    },
    teamOwnerName() {
      return `${this.team?.owner?.firstName || ""} ${this.team?.owner?.lastName || ""}`
    },
    teamOwnerEmail() {
      return this.team?.owner?.email
    },
    isIamTeamLeader() {
      return this.$store.getters["team/isTeamLeader"]
    }
  }
};
</script>

<style scoped>
span.me {
  color: var(--primary-color);
}
th {
  padding: 20px 20px 0 20px;
}
:deep(.user-roles) {
  justify-content: flex-start;
}
:deep(table.ios-style tr td) {
  height: 54px;
  padding: 0 20px;
  line-height: normal;
}
:deep(table.ios-style tr td:last-child) {
  text-align: left;
  color: #000;
}



td {
  width: 25%;
}
.member-name {
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}

/* .member-name.teamleader, .email.teamleader {

} */

:deep(.dropdown.role-dropdown) {
  height: 44px;
  margin-bottom: 0;
}
:deep(.dropdown .dropdown-option) {
  height: 44px;
}
:deep(.dropdown.role-dropdown .selectbox) {
  height: 44px;
}
table.ios-style {
  padding-top: 10px;
}
table.ios-style.table-header {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
table.ios-style.table-header th {
  font-weight: 600;
  font-size: 14px;
}

  /* mobile adaptions */
@media (max-width : 1400px) {
  .member-name {
    float: none;
  }
}

@media only screen and (max-width : 1023px) {
  .team-members-list-view {
    overflow-x: auto;
  }
}
@media only screen and (max-width : 767px) {
  .team-members-list-view {
    overflow-x: auto;
    width: 90vw;
  }
  table.ios-style td.team,
  :deep(.team-form table.ios-style td.cursor-pointer) {
    white-space: nowrap;
  }
}
</style>
