<template>
  <div class="projects-sidebar">
    <SidebarContent>
        <template #content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Ihre Kunden / Projekte
            </h1>
            <div class="subtitle">
              Die Kunden und Projekte, die Sie in der App angelegt haben sind hier gelistet.
              <ul>
                <li>Jedem Kunden ist genau ein Projekt zugeordnet.</li>
                <li>Sie können die Kundendaten in der App bearbeiten, sie werden dann hier aktualisiert.</li>
                <li>Das Hinzufügen und Löschen von von Kunden/Projekten geschieht in der App.</li>
              </ul>
            </div>
          </div>
        </template>
        <template #actions>
          <div class="action-buttons">
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'ProjectsSidebar',
  components: {
      SidebarContent,
  },
  props: {
  },
  methods: {
  }
}
</script>

<style scoped>
.projects-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
ul {
  margin-top: 40px;
}
li {
  text-align: left;
  margin-bottom: 20px;
}
</style>
