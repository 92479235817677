<template>
  <div class="inspector-sidebar">
    <div class="header">
        <router-link to="/"><img class="logo" src="@/assets/img/logo.png"></router-link>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectorSidebar',
  props: {
  }
}
</script>

<style scoped>
.inspector-sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.content {
    padding: 0 10px 40px 40px;
    flex-grow: 1;
}
.header {
    /* height: 400px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo {
  width: 150px;
  margin: 40px 0 40px 0;
}
</style>
