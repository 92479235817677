<template>
  <div class="qr-code">
    <img :src="generatedImgSrc">
  </div>
</template>

<script>

import QRCode from 'qrcode'

export default {
  name: 'QrCode',
  data: function() {
    return {
      generatedImgSrc: ""
    }
  },
  mounted() {
    QRCode.toDataURL(process.env.VUE_APP_CONFIGURATOR_APP_URL)
      .then(url => {
        this.generatedImgSrc = url
      })
      .catch(err => {
        console.error(err)
      })
  }
}
</script>

<style scoped>
.qr-code {
}
</style>
