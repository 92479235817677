<template>
  <div class="action-buttons">
    <button class="button primary tall" :disabled="!isSaveAssortmentEnabled" @click="onSave">{{ (!isSaveAssortmentEnabled && didSave) ? 'Gespeichert' : 'Sortiment Speichern' }}</button>
    <button class="button ghost tall" :disabled="!isResetAssortmentEnabled" @click="onReset">Zurücksetzen</button>
  </div>
</template>
<script>
import { RIGHTS, hasUserRight } from "@profineberater/configurator-clientlib"

export default {
  name: 'AssortmentSaveButtons',
  data: function() {
    return {
      didSave: false
    }
  },
  computed: {
    editingAllowed() {
      return !this.teamEnabled || hasUserRight(RIGHTS.ASSORTMENT_RW)
    },
    isResetAssortmentEnabled() {
      return this.editingAllowed
    },
    isSaveAssortmentEnabled() {
      return this.editingAllowed && this.assortmentPendingChanges && this.assortmentIsValid
    },
    assortment() {
      return this.$store.getters["user/assortment"]
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
    assortmentPendingChanges() {
      return this.$store.getters["user/assortmentPendingChanges"]
    },
    assortmentIsValid() {
      return this.$store.getters["user/assortmentIsValid"]
    }
  },
  methods: {
    onSave() {
      this.$store.dispatch("user/saveAssortment").then( (assortment) => {
        this.didSave = true
        if (this.teamEnabled) {
          this.$store.dispatch("team/saveAssortment", assortment).then( () => {
            this.didSave = true
          }).catch( err => {
            console.error(err)
            this.$store.commit("ui/error", `Beim Synchronisieren mit dem Team ist ein Fehler aufgetreten. Das Sortiment wurde lokal gespeichert. Zum erneuten Synchronisieren Sortiment ändern und wieder speichern. Bei bestehenden Problemen wenden Sie sich bitte an den Support.`)
            this.didSave = false
            //this.$store.commit("user/assortmentPendingChanges", true)
          })
        }
      })
    },
    onReset() {
      this.didSave = false
      this.$store.commit('user/disabledOptions', {})
    }
  }
}
</script>

<style scoped>
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
</style>
