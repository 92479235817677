<template>
  <nav :class="'nav' + (mode == 'mobile' ? ' mobile' : '')">
    <div class="nav-inner">
      <div class="nav-items">
        <div
          v-for="(item, index) in navItemsFiltered" :ref="setItemRef"
          v-bind:key="index"
          :class="'item ' + (activeIndex == index ? 'active' : '') + (item.disabled ? ' disabled' : '') + (item.clazz ? ' ' + item.clazz : '')"
          :title="item.name"
        >
          <router-link :to="item.path" @click="navigate(index)">{{ item.name }}</router-link>
          <div v-if="item.notificationKey && notificationsCount[item.notificationKey]" class="notification-badge">
            <div class="inner-badge">
              <span :class="notificationCountClazz(item.notificationKey)">{{ notificationsCount[item.notificationKey] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-show="mode == 'desktop'" class="active-indicator" ref="activeIndicator"></div>
    </div>
  </nav>
</template>

<script>
import { RIGHTS, ROLES, hasUserRole, hasUserRight } from "@profineberater/configurator-clientlib"

export default {
  name: 'MainNav',
  props: {
    mode: {
      type: String,
      default: "desktop"
    }
  },
  data: function() {
    return {
      itemRefs: [],
      navItems: [
        {
          path: '/',
          aliases: ['/dashboard'],
          name: 'Dashboard',
          notificationKey: 'dashboard'
        },
        {
          path: '/assortment',
          name: 'Sortiment',
          disabled: !this.$store.state.ui.isAssortmentEnabled,
          condition: this.checkAssortmentEnabled
        },
        {
          path: '/globaladmin',
          name: 'Global Admin',
          clazz: 'global',
          needsRight: RIGHTS.GLOBAL_ADMIN_RW
        },
        {
          path: '/projects',
          name: 'Projekte'
        },
        {
          path: '/apps',
          name: 'Apps '
        },
        {
          path: '/team',
          name: 'Team',
        },
        {
          path: '/company',
          name: 'Firmendaten',
          needsRight: RIGHTS.TEAM,
        },
        {
          path: '/legal',
          name: 'Rechtliches'
        },
        {
          path: '/profile',
          name: 'Profil'
        },
        {
          path: '/report',
          name: 'Reports',
          clazz: 'admin analytics',
          needsRole: ROLES.ADMIN_ROLE_ADMIN
        },
      ],
      activeIndex: 1,
      activeIndicatorWidth: 30,
    }
  },
  computed: {
    navItemsFiltered() {
      return this.navItems.filter( item => {
        if (item.needsRight) {
          return hasUserRight(item.needsRight)
        }
        if (item.needsRole) {
          return hasUserRole(item.needsRole)
        }
        if (item.condition) {
          return item.condition()
        }
        return true
      })
    },
    path() {
      return this.$route.path
    },
    notificationsCount() {
      return {
        dashboard: this.$store.getters["user/pendingNotificationsCount"]
      }
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
  },
  methods: {
    checkAssortmentEnabled() {
      if (this.teamEnabled) {
        return hasUserRight(RIGHTS.ASSORTMENT)
      }
      return true
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },
    activeIndicatorStyle() {
      if (this.itemRefs?.length > 0) {
        let itemEl = this.itemRefs[this.activeIndex]
        return {
          width: this.activeIndicatorWidth + 'px',
          left: (itemEl.offsetLeft + ((itemEl.offsetWidth - this.activeIndicatorWidth) / 2) ) + 'px'
        }
      }
      return {
        width: '0px',
        left: '0px'
      }
    },
    updateActiveIndicatorStyle() {
      let activeIndicatorStyle = this.activeIndicatorStyle()
      this.$refs.activeIndicator.style.width = activeIndicatorStyle.width
      this.$refs.activeIndicator.style.left = activeIndicatorStyle.left
    },
    onResize() {
      this.updateActiveIndicatorStyle()
    },
    navigate(index) {
      this.activeIndex = index
      this.updateActiveIndicatorStyle()
      this.$emit("navigate:topath", this.navItemsFiltered[index].path)
    },
    notificationCountClazz(notificationKey) {
      let s = "notification-count"
      if ((this.notificationsCount[notificationKey] || 0) > 9) {
        s += " extended"
      }
      return s
    },
    updateIndexWithPath(path) {
      let level1 = "/" + path.split("/")[1]
      let newIndex = this.navItemsFiltered.findIndex( n => n.path == level1 || (n.aliases && n.aliases.find( a => a == level1)))
      if (newIndex > -1) {
        this.activeIndex = newIndex
      }
    }
  },
  watch: {
    path: function(newPath) {
      this.updateIndexWithPath(newPath)
      this.updateActiveIndicatorStyle()
    },
    notificationsCount: function() {
      this.$nextTick( () => {
        this.updateActiveIndicatorStyle()
      })
    }
  },
  mounted() {
    this.updateIndexWithPath(this.path)
    window.addEventListener("resize", this.onResize)
    this.updateActiveIndicatorStyle()
  },
  onUnmounted() {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>
a:link, a:visited {
  all: unset;
}
nav {
  display: flex;
  flex-direction: column;
}
.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60vw;
  justify-content: flex-start;
}
.nav-inner {
  position: relative;
}
.item {
  cursor: pointer;
  color: var(--text-color);
  font-size: 14px;
  text-align: center;
  margin-right: 40px;
}
.item::after {
  display: block;
  content: attr(title);
  font-weight: 700;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}
.item.active {
  color: #000;
  font-weight: 700;
  padding: 0;
}
.item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.active-indicator {
  position: absolute;
  background-color: var(--primary-color);
  height: 2px;
  transition: left 0.1s ease-in-out;
  margin-top: 12px;
}

.notification-badge {
  width: 17px;
  height: 17px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  border-radius: 10px;
  background-color: #FF5F7E;
  display: inline-block;
  margin-left: 4px;
  transform: translateY(-8px);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.125);
}

.inner-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 17px;
}

.mobile .nav-items {
  flex-direction: column;
}
.mobile .item {
  font-size: 18px;
  margin-right: 0;
  margin-bottom: 20px;
}
.global a {
  color: #FF5F7E;
  font-weight: 500;
}

.item.analytics a {
  color: #1782F3;
}

</style>
